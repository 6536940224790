<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Ciudades
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="flex flex-row w-32 sm:w-24">
                            <select
                                class="h-full w-full sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex md:w-56 w-full">
                            <input placeholder="Buscar" class="appearance-none sm:rounded-r border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" v-model="form.search" @keyup="search" />
                        </div>
                    </div>

                    <div class="mt-4 md:mt-0">
                        <primary-link-expanded :href="route('dashboard.cities.create')">agregar</primary-link-expanded>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="cities?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="cities?.data?.length > 0 && !loading" v-for="(city, index) in cities?.data" :key="index">
                                <table-body-item>
                                    {{city?.state.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{city?.name}}
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <inertia-link :href="route('dashboard.cities.show', city.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>

                                        <inertia-link :href="route('dashboard.cities.edit', city.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-yellow-500 hover:text-yellow-400 transition duration-500 ease">
                                            <edit-solid class="h-5 w-5 "></edit-solid>
                                        </inertia-link>

                                        <button @click="toggleShowDeleteModal(city.id, city.name)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-red-500 hover:text-red-400 transition duration-500 ease">
                                            <trash-solid class="h-5 w-5"></trash-solid>
                                        </button>
                                    </table-body-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                        <pagination :items="cities" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                    </div>

                </div>
            </div>
        </div>

        <dialog-modal :show="showDeleteModal">
            <template #content>
                <h3 class="text-lg leading-6 font-medium text-gray-900">¿Estás seguro de que deseas eliminar "{{city}}"?</h3>
                <p class="text-sm text-gray-500">Esta acción no se puede deshacer.</p>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="toggleShowDeleteModal" :disabled="processing">Cancelar</btn-light>
                    <btn-danger @click="destroy" :disabled="processing">Eliminar</btn-danger>
                </div>
            </template>
        </dialog-modal>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import { Inertia }          from '@inertiajs/inertia'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid          from '@/Components/Icons/HeroIcons/Solid/Search'
    import TrashSolid           from '@/Components/Icons/HeroIcons/Solid/Trash'
    import EyeSolid             from '@/Components/Icons/HeroIcons/Solid/Eye'
    import EditSolid            from '@/Components/Icons/HeroIcons/Solid/Edit'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons     from '@/Components/Tables/TableBodyButtons'
    import PrimaryLinkExpanded  from '@/Components/Buttons/Expanded/Primary'
    import Pagination           from '@/Components/Pagination/Pagination'
    import Loader               from '@/Components/Loader'
    import DialogModal          from '@/Components/DialogModal'
    import BtnLight             from '@/Components/Buttons/Light'
    import BtnDanger            from '@/Components/Buttons/Danger'

    const heading = ['Estado', 'Nombre de la ciudad', 'Opciones']

    export default {
        components: {
            InertiaLink,
            Inertia,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            TrashSolid,
            EyeSolid,
            EditSolid,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            PrimaryLinkExpanded,
            Pagination,
            Loader,
            DialogModal,
            BtnLight,
            BtnDanger
        },
        data() {
            return {
                heading,
                form: {
                    search:     this.getTableVariables().search,
                    perPage:    this.getTableVariables().perPage,
                    pagination: this.getTableVariables().pagination,
                },
                cities:     [],
                tableVariables:     null,
                showDeleteModal:    false,
                id:                 null,
                city:               null,
                loading:            true,
                processing:         false
            }
        },
        methods: {
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination = 1
                    this.form.pagination = 1
                }
                this.tableVariables.perPage = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            },
            async index() {
                this.loading        = true
                const cities        = await axios.post(route('api.cities.index'), this.form)
                this.cities         = cities.data
                this.loading        = false
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination != 1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)
                this.index()
            },
            toggleShowDeleteModal(id, city) {
                this.showDeleteModal = !this.showDeleteModal
                if (this.showDeleteModal) {
                    this.id                 = id
                    this.city               = city
                }
            },
            destroy() {
                this.processing = true
                this.$inertia.delete(route('dashboard.cities.destroy', this.id), {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: page => {
                        this.processing         = false
                        this.showDeleteModal    = false
                        this.id                 = null
                        this.city               = null
                        this.cities             = []
                        this.index()
                    }
                }, {})
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage: 25,
                    pagination: 1,
                    search: ''
                }
                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        }
    }
</script>