<template>
    <div class="h-screen flex flex-col flex-1 bg-gray-100 overflow-hidden">
        <navbar @toggleSidebar="toggleSidebar"></navbar>

        <toast :showToast="showToast"></toast>

        <div class="flex flex-row flex-1 h-screen overflow-hidden">
            <sidebar :show="showSidebar" @toggleSidebar="toggleSidebar"></sidebar>

            <main class="flex flex-col flex-1 h-full">
                <header class="flex-shrink-0 bg-white shadow-sm border-b border-gray-300" v-if="$slots.header">
                    <div class="p-4">
                        <slot name="header"></slot>
                    </div>
                </header>

                <div class="flex-1 max-h-full overflow-y-auto overflow-x-hidden scroll-index">
                    <slot></slot>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import Sidebar              from '@/Layouts/Sidebar/Aside'
    import Navbar               from '@/Layouts/Header/Navbar'
    import Toast                from '@/Components/Toast'

    export default {
        components: {
            Sidebar,
            Navbar,
            Toast
        },
        data() {
            return {
                isotipo:                process.env.APP_URL + '/storage/brand/new_isotipo.svg',
                isDark:                 false,
                showToast:              false,
                showSidebar:            this.getSidebar(),
            }
        },
        methods: {
            toggleToast() {
                this.showToast = this.$page.props.toast?.message!=null
                setTimeout(() => this.showToast = false, 2500)
            },
            getSidebar() {
                if (window.localStorage.getItem('show-sidebar')) return JSON.parse(window.localStorage.getItem('show-sidebar'))
                return false
            },
            toggleSidebar() {
                this.showSidebar = this.getSidebar()
            },

        },
        mounted() {
            this.toggleToast()
        }
    }
</script>

<style scoped>
    /* width */
    .sidebar-scroll::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Track */
    .sidebar-scroll::-webkit-scrollbar-track {
        border-radius: 0px;
        background: transparent;
    }

    /* Handle */
    .sidebar-scroll::-webkit-scrollbar-thumb {
        background: #F3F4F6;
        border-radius: 4px;
        border: 4px solid transparent;
    }

    /* Handle on hover */
    .sidebar-scroll::-webkit-scrollbar-thumb:hover {
        background: #E5E7EB;
    }

    /* width */
    .scroll-index::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    /* Track */
    .scroll-index::-webkit-scrollbar-track {
        border-radius: 0px;
        background: transparent;
    }

    /* Handle */
    .scroll-index::-webkit-scrollbar-thumb {
        background: #BFDBFE;
        border-radius: 4px;
        border: 4px solid transparent;
    }

    /* Handle on hover */
    .scroll-index::-webkit-scrollbar-thumb:hover {
        background: #93C5FD;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>
