<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.representatives.index')">
                        Representantes ambientales
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    {{representative?.name}}
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <div class="col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del representante ambiental
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Datos generales.
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative.name}}
                            </dd>
                        </div>
                    </dl>
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Correo electrónico
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative.email}}
                            </dd>
                        </div>
                    </dl>
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Teléfono
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative.mobile}}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>

        <div class="mx-auto px-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6 flex justify-between">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Empresas generadoras relacionadas
                    </h3>
                </div>
                <div v-if="representative.clients.length" class="border-t border-gray-200">
                    <dl v-for="(client, index) in representative.clients" :key="index">
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div class="flex justify-between">
                                    <div>{{client.data.name}}</div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>

                <div v-else class="border-t border-gray-200">
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div class="text-sm font-medium text-gray-500">
                            Sin empresas relacionadas
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }              from '@inertiajs/inertia-vue3'
    import AppLayout                    from '@/Layouts/AppLayout'
    import BreadcumbNav                 from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem                from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid                    from '@/Components/Icons/HeroIcons/Solid/Home'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        },
        props: ['representative']
    }
</script>