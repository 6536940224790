<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.representatives.index')">
                        Representantes ambientales
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Crear
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 py-6">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label 
                                    for="client" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Empresa generadora</label>

                                <selectize
                                    :isRequired="true"
                                    :options="clients"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="clientSelected"></selectize>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input 
                                    id="name"
                                    type="text"
                                    required
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.name }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="email"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Correo electrónico</label>
                                <input 
                                    id="email"
                                    type="email"
                                    :disabled="form.processing"
                                    v-model="form.email"
                                    autocomplete="givenemail"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.email"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.email }}</div>
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label 
                                    for="mobile"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Teléfono</label>
                                <input 
                                    id="mobile"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.mobile"
                                    autocomplete="givenmobile"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.mobile"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.mobile }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.representatives.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Agregar
                    </btn-success>
                </div>

            </form>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import Spin             from '@/Components/Spin'
    import Selectize        from '@/Components/Inputs/Selectize'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            Spin,
            Selectize
        },
        props: ['clients'],
        data() {
            return {
                form: this.$inertia.form({
                    client: null,
                    name:   '',
                    email:  '',
                    mobile:  ''
                })
            }
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.representatives.store'), {
                    preserveState: (page) => Object.keys(page.props.errors).length,
                    preserveScroll: (page) => Object.keys(page.props.errors).length,
                });
            },
            clientSelected(value) {
                this.form.client = value ? value.id : null
            }
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    if (Object.entries(data.errors).length) {
                        if (data.name != '')    this.form.errors.name       = ''
                        if (data.email != '')   this.form.errors.email      = ''
                        if (data.mobile != '')  this.form.errors.mobile     = ''
                    }
                }
            }
        }
    }
</script>