<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.clients.index')">
                        Clientes
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.clients.show', client.id)">
                        {{client.data.name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="clientNum"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Código de cliente</label>
                                <input
                                    id="clientNum"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.clientNum"
                                    autocomplete="givenclientNum"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.clientNum"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.clientNum }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input
                                    id="name"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.name }}</div>
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label
                                    for="features"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Extras</label>
                                <input
                                    id="features"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.features"
                                    autocomplete="givenfeatures"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.features"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.features }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 lg:col-span-3">
                                <label
                                    for="typeCompany"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Tipo de compañia</label>

                                <selectize
                                    :options="typeCompanies"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de compañia.'"
                                    @input="typeCompanySelected"
                                    :value="setTypeCompany"></selectize>
                                <div
                                    v-if="form.errors.typeCompany"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeCompany }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4 lg:col-span-3">
                                <label
                                    for="phone"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Teléfono</label>
                                <input
                                    id="phone"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.phone"
                                    autocomplete="givenphone"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.phone"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.phone }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4 lg:col-span-3">
                                <label
                                    for="fax"
                                    class="block text-sm font-semibold text-gray-700 mb-1">F.A.X.</label>
                                <input
                                    id="fax"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.fax"
                                    autocomplete="givenfax"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.fax"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.fax }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4 lg:col-span-3">
                                <label
                                    for="rfc"
                                    class="block text-sm font-semibold text-gray-700 mb-1">R.F.C.</label>
                                <input
                                    id="rfc"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.rfc"
                                    autocomplete="givenrfc"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.rfc"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.rfc }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 lg:col-span-8">
                                <label
                                    for="address"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Dirección</label>
                                <input
                                    id="address"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.address"
                                    autocomplete="givenaddress"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.address"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.address }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-2">
                                <label
                                    for="numInt"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nº Interior</label>
                                <input
                                    id="numInt"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.numInt"
                                    autocomplete="givennumInt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.numInt"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.numInt }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-2">
                                <label
                                    for="numExt"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nº Exterior</label>
                                <input
                                    id="numExt"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.numExt"
                                    autocomplete="givennumExt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.numExt"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.numExt }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="state"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Estado</label>

                                <selectize
                                    :options="states"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un estado.'"
                                    @input="stateSelected"
                                    :value="setState"></selectize>
                                <div
                                    v-if="form.errors.state"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.state }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="city"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Ciudad</label>

                                <selectize
                                    :options="cities"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una ciudad.'"
                                    @input="citySelected"
                                    :value="setCity"
                                    :busy="busyCities"></selectize>
                                <div
                                    v-if="form.errors.city"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.city }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="postalCode"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Código postal</label>
                                <input
                                    id="postalCode"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.postalCode"
                                    autocomplete="givenpostalCode"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.postalCode"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.postalCode }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-if="form.typeCompany == 2" class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12" :class="{'md:col-span-6': form.typeWaste == 1 || form.typeWaste == 2, 'lg:col-span-4': form.typeWaste == 3}">
                                <label
                                    for="typeWaste"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Tipo de residuos</label>

                                <selectize
                                    :options="typeWastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de residuo.'"
                                    @input="typeWasteSelected"
                                    :value="setTypeWaste"></selectize>
                                <div
                                    v-if="form.errors.typeWaste"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                            <div v-if="form.typeWaste == 1 || form.typeWaste == 3" class="col-span-12" :class="{'md:col-span-6': form.typeWaste == 1 || form.typeWaste == 2, 'md:col-span-6 lg:col-span-4': form.typeWaste == 3}">
                                <label
                                    for="nra"
                                    class="block text-sm font-semibold text-gray-700 mb-1">N.R.A.</label>
                                <input
                                    id="nra"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.nra"
                                    autocomplete="givennra"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.nra"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.nra }}</div>
                            </div>

                            <div v-if="form.typeWaste == 2 || form.typeWaste == 3" class="col-span-12" :class="{'md:col-span-6': form.typeWaste == 1 || form.typeWaste == 2, 'md:col-span-6 lg:col-span-4': form.typeWaste == 3}">
                                <label
                                    for="nare"
                                    class="block text-sm font-semibold text-gray-700 mb-1">N.A.R.E.</label>
                                <input
                                    id="nare"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.nare"
                                    autocomplete="givennare"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.nare"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.nare }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Sede</label>
                                <selectize
                                    :options="subsidiaries"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una sede.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary"
                                    label="descriptive_name" :keys="['descriptive_name', 'id']"></selectize>
                                <div
                                    v-if="form.errors.subsidiary"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.clients.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Actualizar
                    </btn-warning>
                </div>

            </form>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'
    import moment           from 'moment'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            Selectize,
            Spin
        },
        props: ['client', 'subsidiaries', 'states'],
        data() {
            return {
                form: this.$inertia.form({
                    clientNum:                  null,
                    name:                       null,
                    features:                   null,
                    typeCompany:                null,
                    phone:                      null,
                    fax:                        null,
                    rfc:                        null,
                    address:                    null,
                    numInt:                     null,
                    numExt:                     null,
                    state:                      null,
                    city:                       null,
                    postalCode:                 null,
                    typeWaste:                  null,
                    nra:                        null,
                    nare:                       null,
                    subsidiary:                 null,
                }),
                typeWastes:                     [{id: 1, name: 'Peligrosos'}, {id: 2, name: 'No peligrosos'}, {id: 3, name: 'Peligrosos y no peligrosos'}],
                typeCompanies:                  [{id: 1, name: 'Cliente'}, {id: 2, name: 'Cliente y generador'}],
                cities:                         [],
                setState:                       null,
                setCity:                        null,
                setTypeWaste:                   null,
                setSubsidiary:                  null,
                setTypeCompany:                 null,
                busyCities:                     false,
                firstTime:                      true,
            }
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.clients.update', this.client.id));
            },
            existence: _.debounce(async function () {
                if (this.form.clientNum != this.client.client_num) {
                    const response = await axios.post(route('api.clients.existence'), this.form)
                    if (response.data.message) {
                        this.form.errors.clientNum = response.data.message
                    } else {
                        this.form.errors.clientNum = ''
                    }
                }
            }, 300),
            init() {
                this.form.clientNum             = this.client.client_num
                this.form.name                  = this.client.data.name
                this.form.features              = this.client.features
                this.form.phone                 = this.client.data.phone
                this.form.fax                   = this.client.data.fax
                this.form.rfc                   = this.client.data.rfc
                this.form.address               = this.client.data.address
                this.form.numInt                = this.client.data.num_int
                this.form.numExt                = this.client.data.num_ext
                this.setState                   = parseInt(this.client.data.state)
                this.stateSelected(parseInt(this.client.data.state))
                this.form.postalCode            = this.client.data.postal_code
                this.form.typeCompany           = this.client.data.type_company == 'cliente'     ? 1 : 2
                this.setTypeCompany             = this.form.typeCompany

                if (this.client.data.type_waste == 'Peligrosos' || this.client.data.type_waste == 'Peligrosos y no peligrosos') {
                    this.form.nra      = this.client.data.nra
                }

                if (this.client.data.type_waste == 'No peligrosos' || this.client.data.type_waste == 'Peligrosos y no peligrosos') {
                    this.form.nare     = this.client.data.nare
                }

                this.form.subsidiary            = this.client.subsidiary_id
                this.setSubsidiary              = this.form.subsidiary
            },
            typeCompanySelected(val) {
                this.form.typeCompany   = val ? val.id : null
            },
            async stateSelected(val) {
                this.busyCities     = true
                this.form.city      = null
                this.setCity        = -1
                if (val) {
                    this.form.state = typeof val == 'object' ? val.id : val
                    this.cities     = []
                    const cities    = await axios.post(route('municipalities.getMunicipalitiesByState'), {id: this.form.state})
                    this.cities     = cities.data.municipalities

                } else {
                    this.form.state = null
                    this.cities     = []
                }
                this.busyCities     = false
            },
            citySelected(val) {
                this.form.city          = val ? val.id : null
            },
            typeWasteSelected(val) {
                this.form.typeWaste     = val ? val.id : null
            },
            subsidiarySelected(val) {
                this.form.subsidiary    = val ? val.id : null
            },
        },
        updated() {
            this.setState               = this.form.state                   ? this.form.state                   : -1
            this.setTypeCompany         = this.form.typeCompany             ? this.form.typeCompany             : -1
            this.setTypeWaste           = this.form.typeWaste               ? this.form.typeWaste               : -1
            this.setCity                = this.form.city                    ? this.form.city                    : -1
            this.setSubsidiary          = this.form.subsidiary              ? this.form.subsidiary              : -1
        },
        mounted() {
            this.init()
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    console.log(data)
                }
            },
            cities: {
                deep: true,
                handler(data) {
                    if (data.length && this.firstTime) {
                        this.form.city             = parseInt(this.client.data.city)
                        this.setCity               = parseInt(this.client.data.city)
                        this.firstTime             = false

                        this.form.typeWaste             = this.client.data.type_waste == 'Peligrosos'   ? 1 : (this.client.data.type_waste == 'No peligrosos' ? 2 : 3)
                        this.setTypeWaste               = this.form.typeWaste
                    }
                }
            }
        }
    }
</script>