<template>
    <transition name="slide-fade">
        <div :class="{ 'bg-green-500': style() == 'success', 'bg-red-500': style() == 'danger', 'bg-yellow-500': style() == 'warning', 'bg-indigo-500': style() == 'info' }" v-if="$page.props.toast && show || showToast" class="absolute flex max-w-md mt-4 right-7 rounded shadow">
            <div class="flex items-center justify-between flex-wrap p-4">
                <div class="w-full flex-1 flex items-center min-w-0">
                    <span class="flex p-2 rounded-lg" :class="{ 'bg-green-600': style() == 'success', 'bg-red-600': style() == 'danger', 'bg-yellow-600': style() == 'warning', 'bg-indigo-600': style() == 'info' }">
                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="style() == 'success' || style() == 'info'">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="style() == 'danger' || style() == 'warning'">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </span>

                    <p class="ml-3 font-medium text-sm text-white">
                        {{ message() }}
                    </p>
                </div>

                <!-- <div class="flex-shrink-0">
                    <button
                        type="button"
                        class="flex p-2 rounded-lg focus:outline-none transition"
                        :class="{ 'hover:bg-green-600 focus:bg-green-600': style() == 'success', 'hover:bg-red-600 focus:bg-red-600': style() == 'danger','hover:bg-yellow-600 focus:bg-yellow-600': style() == 'warning', 'hover:bg-indigo-600 focus:bg-indigo-600': style() == 'info' }"
                        aria-label="Dismiss"
                        @click.prevent="show = false">
                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div> -->
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                timeout: null,
            }
        },
        props: {showToast: Boolean},
        methods: {
            style() {
                return this.$page.props.toast?.style || 'success';
            },

            message() {
                return  this.$page.props.toast?.message || '';
            },
        },
        watch: {
            '$page.props.toast': {
                deep: true,
                handler() {
                    this.show = true;

                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }

                    this.timeout = setTimeout(() => this.show = false, 2000);
                }
            }
        }
    }
</script>

<style >
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
