<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    Bitácoras
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    C.O.A.
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <selectize
                                    :options="subsidiaries"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary"
                                    :label="'descriptive_name'" :keys="['descriptive_name', 'id']"></selectize>
                                <div 
                                    v-if="hasErrorSubsidiary" 
                                    class="text-red-500 text-xs mt-2">El campo sede es obligatorio.</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <selectize
                                    :options="generators"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="generatorSelected" 
                                    :value="setGenerator"></selectize>
                                <div 
                                    v-if="hasErrorGenerator" 
                                    class="text-red-500 text-xs mt-2">El campo empresa generadora es obligatorio.</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div class="col-span-1 md:col-span-6">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <selectize
                                    :options="typeWastes"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="typeWasteSelected"
                                    :value="setTypeWaste"></selectize>
                                <div 
                                    v-if="hasErrorTypeWaste"
                                    class="text-red-500 text-xs mt-2">El campo tipo de residuos es obligatorio.</div>
                            </div>

                            <div class="col-span-1 md:col-span-3">
                                <label 
                                    for="since"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha desde</label>

                                <input 
                                    id="since"
                                    type="date"
                                    :disabled="loading"
                                    v-model="form.since"
                                    autocomplete="givensince"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="loading ? 'cursor-not-allowed' : 'cursor-default'" />

                                
                            </div>

                            <div class="col-span-1 md:col-span-3">
                                <label 
                                    for="until"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha hasta</label>

                                <input 
                                    id="until"
                                    type="date"
                                    :disabled="loading"
                                    v-model="form.until"
                                    autocomplete="givenuntil"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="loading ? 'cursor-not-allowed' : 'cursor-default'" />

                                
                            </div>
                        </div>
                    </div>

                </div>
                    
                <div class="pt-4 text-right space-x-4">
                    <btn-success @click="submit" type="button" :disabled="loading">
                        <spin v-show="loading"></spin>
                        Exportar
                    </btn-success>
                </div>

                <div v-if="showMessage" class="mt-4 shadow-sm sm:rounded-md bg-white border border-gray-300">
                    <span v-if="!showFile" class="flex items-center px-3 py-2 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                        {{manifestWastes.toast.message}}
                    </span>
                    
                    <a v-else download :href="file" class="flex items-center px-3 py-2 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                        bitácora_coa.xlsx
                    </a>
                </div>

        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment           from 'moment'
    import Spin             from '@/Components/Spin'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import Selectize        from '@/Components/Inputs/Selectize'

    export default {
        components: {
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            Spin,
            BtnSuccess,
            Selectize,
            InertiaLink
        },
        props: ['subsidiaries'],
        data() {
            return {
                form: {
                    subsidiary:     null,
                    generator:      null,
                    typeWaste:      null,
                    since:          null,
                    until:          null
                },
                typeWastes:         [{id: -1, name: 'TODOS'}, {id: 1, name: 'Peligrosos'}, {id: 2, name: 'No peligrosos'}],
                generators:         [],
                manifestWastes:     [],
                loading:            false,
                hasErrorSubsidiary: false,
                hasErrorGenerator:  false,
                hasErrorTypeWaste:  false,
                file:               process.env.APP_URL + '/files/coa/bitácora_coa.xlsx',
                showFile:           false,
                showMessage:        false,
                setSubsidiary:      null,
                setGenerator:       null,
                setTypeWaste:       null,
                processing:         false
            }
        },
        methods: {
            async submit() {
                if (!this.form.subsidiary) {
                    this.hasErrorSubsidiary = true
                } else {
                    this.hasErrorSubsidiary = false
                }

                if (!this.form.generator) {
                    this.hasErrorGenerator = true
                } else {
                    this.hasErrorGenerator = false
                }

                if (!this.form.typeWaste) {
                    this.hasErrorTypeWaste = true
                } else {
                    this.hasErrorTypeWaste = false
                }

                if (this.form.subsidiary && this.form.generator && this.form.typeWaste) {
                    const ourRequest = axios.CancelToken.source()
                    const fetchPost  = async () => {
                        try {
                            this.loading     = true
                            await axios.post(route('api.binnacles.coa.index'), this.form, {
                                cancelToken: ourRequest.token,
                            }).then((res) => {
                                console.log(res)
                                this.showMessage = true
                                this.manifestWastes = res.data
                                if (res.data.toast.manifest == 1) {
                                    setTimeout(() => {
                                        this.loading    = false
                                        this.showFile   = true
                                    }, 100000);
                                } else {
                                    this.loading    = false
                                    this.showFile   = false
                                }
                            })
                        } catch (err) {
                            console.log('There was a problem or request was cancelled.')
                            this.loading = false
                        }
                    }
                    fetchPost()

                    return () => {
                        ourRequest.cancel()
                    }
                }
            },
            async subsidiarySelected(value) {
                if (value) {
                    this.form.subsidiary    = value.id
                    const generators        = await axios.post(route('api.clients.subsidiary'), {id: value.id})
                    this.generators         = generators.data
                }
            },
            generatorSelected(value) {
                this.form.generator = value ? value.name : null
            },
            typeWasteSelected(value) {
                this.form.typeWaste = value ? value.name : null
            }
        },
        updated() {
            this.setSubsidiary  = this.fomr.subsidiary  ? this.fomr.subsidiary : -1
            this.setGenerator   = this.fomr.generator   ? this.fomr.generator  : -1
            this.setTypeWaste   = this.fomr.typeWaste   ? this.fomr.typeWaste  : -1
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    this.hasErrorSubsidiary = !data.subsidiary ? true : false
                    this.hasErrorGenerator  = !data.generator  ? true : false
                    this.hasErrorTypeWaste  = !data.typeWaste  ? true : false
                }
            }
        }
    }
</script>