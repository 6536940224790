<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.wastes.index')">
                        Residuos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.wastes.show', waste.id)">
                        {{waste.name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
                <form @submit.prevent="submit">
                    <div class="shadow sm:rounded-md bg-white">

                        <div class="px-6 pt-6 pb-3">
                            <div class="grid grid-cols-1 gap-4">
                                <div class="col-span-1">
                                    <label
                                        for="generator"
                                        class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                    <input 
                                        id="generator"
                                        type="text"
                                        disabled
                                        v-model="form.generatorName"
                                        autocomplete="givengenerator"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                    <div 
                                        v-if="form.errors.generator" 
                                        class="text-red-500 text-xs">{{ form.errors.generator }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="px-6" :class="form.typeWaste === 'Peligrosos' ? 'py-3' : 'pt-3 pb-6'">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">

                            <div class="col-span-1 sm:col-span-2 md:col-span-1">
                                <label 
                                    for="typeWaste" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Tipo de residuos</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="2"
                                    placeholder="Seleccionar un tipo de residuo"
                                    label="name"
                                    @option:selected="typeWasteSelected"
                                    v-model="form.typeWaste"
                                    :reduce="(option) => option.name"
                                    :options="typeWastes">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.typeWaste" 
                                    class="text-red-500 text-xs">{{ form.errors.typeWaste }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="crp" 
                                    class="block text-sm font-semibold text-gray-700 mb-1" v-text="crpLabel"></label>
                                <input 
                                    id="crp"
                                    type="text"
                                    disabled
                                    v-model="form.crp"
                                    autocomplete="givencrp"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div
                                    v-if="form.errors.crp"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.crp }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre del residuo</label>
                                <input 
                                    id="name"
                                    type="text"
                                    tabindex="3"
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.name }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.typeWaste=== 'Peligrosos'" class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-2">
                                <label 
                                    for="rpbi"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Residuo R.P.B.I</label>
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="rpbi"
                                            type="checkbox"
                                            :value="form.rpbi"
                                            :disabled="form.processing"
                                            v-model="form.rpbi"
                                            @click="rpbiChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="rpbi" class="font-medium text-gray-700" v-text="rpbiLabel"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-5">
                                <label 
                                    for="generic" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Grupo generico</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="4"
                                    placeholder="Seleccionar un grupo generico"
                                    label="name"
                                    v-model="form.generic"
                                    :reduce="(option) => option.id"
                                    :options="genericsList">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.generic"
                                    class="text-red-500 text-xs">{{ form.errors.generic }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-5">
                                <label 
                                    for="container" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Contenedor</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="5"
                                    placeholder="Seleccionar un contenedor"
                                    label="name"
                                    v-model="form.container"
                                    :reduce="(option) => option.id"
                                    :options="containers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.container" 
                                    class="text-red-500 text-xs">{{ form.errors.container }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.typeWaste=== 'Peligrosos'" class="px-6" :class="form.rpbi===false ? 'py-3' : 'pt-3 pb-6'">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 lg:col-span-5">
                                <label 
                                    for="classification" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Clasificación</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="6"
                                    placeholder="Seleccionar una clasificación"
                                    label="name"
                                    @search="onSearchClassification"
                                    v-model="form.classification"
                                    :reduce="(option) => option.id"
                                    :options="classifications">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.classification" 
                                    class="text-red-500 text-xs">{{ form.errors.classification }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-10 lg:col-span-5">
                                <label 
                                    for="physicalState" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Estado físico</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="6"
                                    placeholder="Seleccionar un estado físico"
                                    label="name"
                                    v-model="form.physicalState"
                                    :reduce="(option) => option.id"
                                    :options="physicalstates">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.physicalState" 
                                    class="text-red-500 text-xs">{{ form.errors.physicalState }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-2">
                                <label 
                                    for="mixture" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Mezcla</label>

                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="mixture"
                                            type="checkbox"
                                            tabinde="7"
                                            :disabled="form.processing"
                                            v-model="form.mixture"
                                            @click="mixtureChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="mixture" class="font-medium text-gray-700" v-text="mixtureLabel"></label>
                                    </div>
                                </div>

                                <div
                                    v-if="form.errors.mixture"
                                    class="text-red-500 text-xs">{{ form.errors.mixture }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.rpbi === false && form.typeWaste=== 'Peligrosos'" class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label 
                                    for="transportname" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre de transporte S.C.T.</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="8"
                                    placeholder="Seleccionar un nombre de transporte"
                                    label="name"
                                    v-model="form.transportname"
                                    :reduce="(option) => option.id"
                                    :options="transportnames">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.transportname" 
                                    class="text-red-500 text-xs">{{ form.errors.transportname }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="numberun" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Número U.N.</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="10"
                                    placeholder="Seleccionar un número u.n."
                                    label="name"
                                    v-model="form.numberun"
                                    :reduce="(option) => option.id"
                                    :options="numbersun">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.numberun"
                                    class="text-red-500 text-xs">{{ form.errors.numberun }}</div>
                            </div>

                        </div>
                    </div>
                    
                    <div v-show="form.rpbi===false && form.typeWaste=== 'Peligrosos'" class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="hazard"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Caracteristicas CRETIB</label>

                                <div v-for="(hazard, index) in hazards" :key="index" class="inline-flex items-start space-x-2 mr-3">
                                    <div class="flex items-center h-5">
                                        <input
                                            :id="`hazard${hazard.id}`"
                                            type="checkbox"
                                            :value="hazard.id"
                                            :disabled="form.processing"
                                            v-model="form.hazards"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="mr-3 text-sm">
                                        <label :for="`hazard${hazard.id}`" class="font-medium text-gray-700" v-text="hazard.name"></label>
                                    </div>
                                </div>

                                <div
                                    v-if="form.errors.hazards"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.hazards }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="generic"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Clase</label>

                                <div v-for="(level, index) in levels" :key="index" class="inline-flex items-start space-x-2 mr-3">
                                    <div class="flex items-center h-5">
                                        <input
                                            :id="`level${level.id}`"
                                            type="checkbox"
                                            :value="level.id"
                                            :disabled="form.processing"
                                            v-model="form.levels"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="text-sm">
                                        <label :for="`level${level.id}`" class="font-medium text-gray-700" v-text="level.name"></label>
                                    </div>
                                </div>
                                <div
                                    v-if="form.errors.levels"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.levels }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="packingGroup" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Packing group</label>

                                <div class="inline-flex items-start space-x-2">
                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupI" 
                                            v-model="form.packingGroup" 
                                            value="I" 
                                            checked 
                                            :disabled="form.processing"
                                            type="radio" 
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            I
                                        </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupII" 
                                            v-model="form.packingGroup" 
                                            value="II" 
                                            type="radio" 
                                            :disabled="form.processing"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            II
                                        </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupIII" 
                                            v-model="form.packingGroup" 
                                            value="III" 
                                            type="radio" 
                                            :disabled="form.processing"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            III
                                        </label>
                                    </div>
                                </div>

                                <div
                                    v-if="form.errors.packingGroup" 
                                    class="text-red-500 text-xs mt-2">{{ form.errors.packingGroup }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.rpbi===false && form.typeWaste=== 'Peligrosos'" class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="integratedTransportName"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre de transporte integrado</label>
                                <input 
                                    id="integratedTransportName"
                                    type="text"
                                    disabled
                                    v-model="form.integratedTransportName"
                                    autocomplete="givenintegratedTransportName"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div
                                    v-if="form.errors.integratedTransportName"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.integratedTransportName }}</div>
                            </div>
                        </div>
                    </div>

                    </div>

                    <div class="pt-6 text-right space-x-4">
                        <link-light :href="route('dashboard.wastes.index')" :disabled="form.processing">Cancelar</link-light>
                        <btn-warning type="submit" :disabled="form.processing">
                            <spin class="text-white" v-show="form.processing"></spin>
                            Actualizar
                        </btn-warning>
                    </div>
                </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Spin             from '@/Components/Spin'
    import vSelect          from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            Spin,
            vSelect
        },
        props: ['waste', 'containers', 'generics', 'genericsRpbi', 'transportnames', 'numbersun', 'hazards', 'levels', 'physicalstates'],
        data() {
            return {
                form: this.$inertia.form({
                    generator:                null,
                    generatorName:              null,
                    typeWaste:                  null,
                    crp:                        '',
                    name:                       '',
                    container:                  null,
                    rpbi:                       false,
                    mixture:                    false,
                    physicalState:              null,
                    classification:             null,
                    generic:                    null,
                    transportname:              null,
                    numberun:                   null,
                    hazards:                    [],
                    levels:                     [],
                    packingGroup:               'I',
                    integratedTransportName:    ''
                }),
                rpbiLabel:                      'No',
                crpLabel:                       'Número',
                mixtureLabel:                   'No',
                typeWastes:                     [],
                genericsList:                   [],
                classifications:                [],
            } 
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.wastes.update', this.waste.id), this.form, {preserveScroll: true})
            },
            init() {
                this.form.generator     = this.waste?.clients ? this.waste.clients[0]           : null
                this.form.generatorName = this.form.generator ? this.form.generator.data.name   : ''


                if (this.form.generator.data.type_waste == 'Peligrosos y no peligrosos') {
                    this.typeWastes = [{id: 1, name: 'Peligrosos'}, {id: 2, name: 'No peligrosos'}]
                }

                if (this.form.generator.data.type_waste == 'Peligrosos') {
                    this.typeWastes = [{id: 1, name: 'Peligrosos'}]
                }

                if (this.form.generator.data.type_waste == 'No peligrosos') {
                    this.typeWastes     = [{id: 2, name: 'No peligrosos'}]
                }

                this.form.typeWaste = this.waste.type_waste
                this.form.crp            = this.waste.crp_num
                this.form.name           = this.waste.name
                this.form.rpbi           = this.waste.status_rpbi == '0' ? false : true
                this.rpbiLabel           = this.form.rpbi                ? 'Si'  : 'No'
                this.form.mixture        = this.waste.mixture
                this.mixtureLabel        = this.waste.mixture            ? 'Si'  : 'No'
                this.form.physicalState  = this.waste.physicalstate_id

                const classificationName = this.waste.classification ? this.waste.classification.name : ''
                
                axios.post(route('api.classifications.selectize'), {'search': classificationName}).then(res => {
                    this.classifications          = res.data.length ? res.data : []
                    this.form.classification    = this.waste.classification_id
                })

                if (this.waste.type_waste === 'Peligrosos') {
                    this.genericsList           = this.form.rpbi ? this.genericsRpbi : this.generics
                    this.form.generic           = this.waste.generic.id
                    this.form.container         = this.waste.container.id

                    if (!this.form.rpbi) {
                        this.form.transportname     = this.waste.transportname.id
                        this.form.numberun          = this.waste.numberun.id

                        for (let i = 0; i < this.waste.hazards.length; i++) {
                            this.form.hazards.push(this.waste.hazards[i].id)
                        }

                        for (let i = 0; i < this.waste.levels.length; i++) {
                            this.form.levels.push(this.waste.levels[i].id)
                        }

                        this.form.packingGroup      = this.waste.packing_group
                    }
                }
            },
            typeWasteSelected(val) {
                this.crpLabel           = val ? (val.id == 1 ? 'Número C.R.P.' : 'Número C.R.N.P.') : 'Número'
                if (val && val.id == 1) this.genericsList = this.form.rpbi ? this.genericsRpbi : this.generics
            },
            rpbiChange() {
                this.form.rpbi      = !this.form.rpbi
                this.rpbiLabel      = this.form.rpbi ? 'Si'                 : 'No'
                this.genericsList   = this.form.rpbi ? this.genericsRpbi    : this.generics
                this.form.generic   = null
            },
            mixtureChange() {
                this.form.mixture   = !this.form.mixture
                this.mixtureLabel   = this.form.mixture ? 'Si'              : 'No'
            },
            onSearchGenerator(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchGenerator(loading, search, this)
                }
            },
            searchGenerator: _.debounce((loading, search, vm) => {
                axios.post(route('api.clients.selectize'), {'search': search}).then(res => {
                    vm.clients = res.data.length ? res.data : []
                    loading(false)
                })
            }, 350),
            onSearchClassification(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchClassification(loading, search, this)
                }
            },
            searchClassification: _.debounce((loading, search, vm) => {
                axios.post(route('api.classifications.selectize'), {'search': search}).then(res => {
                    vm.classifications = res.data.length ? res.data : []
                    loading(false)
                })
            }, 350),
        },
        mounted() {
            this.init()
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    var hazardsLetter   = []
                    var levelsLetter    = []
                    var numberun        = null
                    var transportname   = null

                    if (data.hazards.length>0) {
                        for (let i = 0; i < this.hazards.length; i++) {
                            data.hazards.forEach(element => {
                                if (this.hazards[i].id === element) {
                                    hazardsLetter.push(this.hazards[i].name.substring(0,1))
                                }
                            });
                        }
                    }

                    if (data.levels.length>0) {
                        for (let i = 0; i < this.levels.length; i++) {
                            data.levels.forEach(element => {
                                if (this.levels[i].id === element) {
                                    levelsLetter.push(this.levels[i].name.substring(0,1))
                                }
                            });
                        }
                    }

                    if (data.numberun!==null) {
                        for (let i = 0; i < this.numbersun.length; i++) {
                            if (this.numbersun[i].id === data.numberun) {
                                numberun = this.numbersun[i].name
                            }
                        }
                    }

                    if (data.transportname!==null) {
                        for (let i = 0; i < this.transportnames.length; i++) {
                            if (this.transportnames[i].id === data.transportname) {
                                transportname = this.transportnames[i].name
                            }
                        }
                    }

                    this.form.integratedTransportName = `${transportname ? transportname : 'Nombre de transporte'} (${hazardsLetter.length>0 ? hazardsLetter : 'Caracteristicas CRETIB'}) ${levelsLetter.length>0 ? levelsLetter : 'Clase'} ${numberun ? numberun : 'Número U.N.'}, ${data.packingGroup ? data.packingGroup : 'Packing group'} (${data.name ? data.name : 'Nombre del residuo'})`
                }
            }
        }
    }
</script>