<template>
    <a href="#" @click="open"
        class="flex items-center p-2 text-gray-500 transition-colors rounded-md dark:text-light hover:bg-blue-300 hover:text-gray-800"
        :class="{'bg-blue-300 text-gray-800': active || show}" 
        role="button"
        aria-haspopup="true"
        :aria-expanded="(show || active) ? 'true' : 'false'">

        <span aria-hidden="true">
            <slot name="icon"></slot>
        </span>

        <span class="ml-2 text-sm">
            <slot name="title"></slot>
        </span>

        <span class="ml-auto" aria-hidden="true">
            <arrow-down class="w-4 h-4 transition-transform transform" :class="{ 'rotate-180': show }"></arrow-down>
        </span>
    </a>

    <div role="menu" v-show="show" class="mt-2 space-y-2 px-7">
        <slot></slot>
    </div>
</template>

<script>
    import ArrowDown from '@/Components/icons/HeroIcons/Solid/ArrowDown';

    export default {
        components: {
            ArrowDown
        },
        props: {
            active: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                show: this.active
            }
        },
        methods: {
            open() {
                this.show = !this.show;
            }
        }
    }
</script>