<template>
    <button :type="type" :disabled="disabled" :class="disabled===true ? 'cursor-not-allowed' : 'cursor-pointer'" class="inline-flex items-center px-4 py-2 bg-gray-200 border border-gray-200 sm:rounded font-semibold text-xs text-gray-800 uppercase tracking-widest select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>