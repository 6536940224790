<template>
    <div v-if="showSidebar">
        <aside v-show="!showResponsiveSidebar" class="z-10 flex-shrink-0 w-64 bg-white border-r focus:outline-none h-full overscroll-y-auto overflow-x-hidden sidebar-scroll">

            <div class="flex flex-col">
                <nav class="flex-1 px-2 py-4 space-y-2">
                    <sidebar-simple-item :href="route('dash')" :active="isRoute('dash')">
                        <template v-slot:icon>
                            <home-outline class="w-5 h-5"></home-outline>
                        </template>

                        <template v-slot:title>
                            <span>Dashboard</span>
                        </template>
                    </sidebar-simple-item>

                    <!-- <sidebar-dropdown :active="isRoute('dashboard.permissions.index', 'dashboard.permissions.create', 'dashboard.permissions.show', 'dashboard.permissions.edit')">
                        <template v-slot:icon>
                            <shield-check-outline class="w-5 h-5"></shield-check-outline>
                        </template>

                        <template v-slot:title>
                            <span>Autorización</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.permissions.index')" :active="isRoute('dashboard.permissions.index', 'dashboard.permissions.create', 'dashboard.permissions.show', 'dashboard.permissions.edit')">Permisos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :inertiaLink="false" :href="route('roles.index')"><template #content>Roles</template></sidebar-dropdown-item>
                    </sidebar-dropdown> -->

                    <sidebar-dropdown :active="isRoute('dashboard.numbersun.index', 'dashboard.numbersun.show', 'dashboard.numbersun.create', 'dashboard.numbersun.edit', 'dashboard.transportnames.index', 'dashboard.transportnames.show', 'dashboard.transportnames.create', 'dashboard.transportnames.edit', 'dashboard.cities.index', 'dashboard.cities.show', 'dashboard.cities.create', 'dashboard.cities.edit', 'dashboard.generics.index', 'dashboard.generics.show', 'dashboard.generics.create', 'dashboard.generics.edit', 'dashboard.subsidiaries.index', 'dashboard.subsidiaries.show', 'dashboard.subsidiaries.create', 'dashboard.subsidiaries.edit', 'dashboard.authorizations.index', 'dashboard.authorizations.show', 'dashboard.authorizations.create', 'dashboard.authorizations.edit')">
                        <template v-slot:icon>
                            <globe-outline class="w-5 h-5"></globe-outline>
                        </template>

                        <template v-slot:title>
                            <span v-if="$page.props.auth.user.subsidiary.id == 1">Hermosillo</span>
                            <span v-if="$page.props.auth.user.subsidiary.id == 2">Nogales</span>
                            <span v-if="$page.props.auth.user.subsidiary.id == 4">Mexicali</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.authorizations.index')" :active="isRoute('dashboard.authorizations.index', 'dashboard.authorizations.show', 'dashboard.authorizations.create', 'dashboard.authorizations.edit')">
                            Autorizaciones
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.cities.index')" :active="isRoute('dashboard.cities.index', 'dashboard.cities.show', 'dashboard.cities.create', 'dashboard.cities.edit')">
                            Ciudades
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :inertiaLink="false" :href="route('employees.index')">
                            <template #content>Empleados</template>
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.generics.index')" :active="isRoute('dashboard.generics.index', 'dashboard.generics.show', 'dashboard.generics.create', 'dashboard.generics.edit')">
                            Genericos
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.transportnames.index')" :active="isRoute('dashboard.transportnames.index', 'dashboard.transportnames.show', 'dashboard.transportnames.create', 'dashboard.transportnames.edit')">
                            Nombres de transporte
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.numbersun.index')" :active="isRoute('dashboard.numbersun.index', 'dashboard.numbersun.show', 'dashboard.numbersun.create', 'dashboard.numbersun.edit')">
                            Números U.N.
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.subsidiaries.index')" :active="isRoute('dashboard.subsidiaries.index', 'dashboard.subsidiaries.show', 'dashboard.subsidiaries.create', 'dashboard.subsidiaries.edit')">
                            Sucursales
                        </sidebar-dropdown-item>
                    </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.carriers.index', 'dashboard.carriers.show', 'dashboard.carriers.create', 'dashboard.carriers.edit', 'dashboard.recipients.index', 'dashboard.recipients.show', 'dashboard.recipients.create', 'dashboard.recipients.edit')">
                        <template v-slot:icon>
                            <puzzle-outline class="w-5 h-5"></puzzle-outline>
                        </template>

                        <template v-slot:title>
                            <span>Proveedores</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.carriers.index')" :active="isRoute('dashboard.carriers.index', 'dashboard.carriers.show', 'dashboard.carriers.create', 'dashboard.carriers.edit')">Transportistas</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.recipients.index')" :active="isRoute('dashboard.recipients.index', 'dashboard.recipients.show', 'dashboard.recipients.create', 'dashboard.recipients.edit')">Destinatarios</sidebar-dropdown-item>
                    </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.wastes.index', 'dashboard.wastes.create', 'dashboard.wastes.show', 'dashboard.wastes.edit','dashboard.agreements.index', 'dashboard.agreements.create', 'dashboard.agreements.show', 'dashboard.agreements.edit', 'dashboard.clients.index', 'dashboard.clients.create', 'dashboard.clients.show', 'dashboard.clients.edit', 'dashboard.representatives.index', 'dashboard.representatives.create', 'dashboard.representatives.show', 'dashboard.representatives.edit')">
                        <template v-slot:icon>
                            <user-group-outline class="w-5 h-5"></user-group-outline>
                        </template>

                        <template v-slot:title>
                            <span>Clientes</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.clients.index')" :active="isRoute('dashboard.clients.index', 'dashboard.clients.create', 'dashboard.clients.show', 'dashboard.clients.edit')">Clientes</sidebar-dropdown-item>
                        <sidebar-dropdown-item :inertiaLink="false" :href="route('generators.index')">
                            <template #content>Generadores</template>
                        </sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.wastes.index')" :active="isRoute('dashboard.wastes.index', 'dashboard.wastes.create', 'dashboard.wastes.show', 'dashboard.wastes.edit')">Residuos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.representatives.index')" :active="isRoute('dashboard.representatives.index', 'dashboard.representatives.create', 'dashboard.representatives.show', 'dashboard.representatives.edit')">Representantes amb</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.agreements.index')" :active="isRoute('dashboard.agreements.index', 'dashboard.agreements.create', 'dashboard.agreements.show', 'dashboard.agreements.edit')">Contratos</sidebar-dropdown-item>
                    </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.manifests.consolidate.index', 'dashboard.manifests.consolidate.show', 'dashboard.manifests.consolidate.edit', 'dashboard.manifests.consolidated.index', 'dashboard.manifests.consolidated.show', 'dashboard.manifests.cancelled.index', 'dashboard.manifests.cancelled.show', 'dashboard.manifests.open.index', 'dashboard.manifests.open.show', 'dashboard.manifests.open.create', 'dashboard.manifests.open.edit', 'dashboard.manifests.all.index', 'dashboard.manifests.all.show', 'dashboard.manifests.open.print')">
                        <template v-slot:icon>
                            <badge-check-outline class="w-5 h-5"></badge-check-outline>
                        </template>

                        <template v-slot:title>
                            <span>Manifiestos</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.manifests.all.index')" :active="isRoute('dashboard.manifests.all.index', 'dashboard.manifests.all.show')">Todos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.manifests.open.index')" :active="isRoute('dashboard.manifests.open.index', 'dashboard.manifests.open.show', 'dashboard.manifests.open.create', 'dashboard.manifests.open.edit', 'dashboard.manifests.open.print')">Abiertos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.manifests.consolidate.index')" :active="isRoute('dashboard.manifests.consolidate.index', 'dashboard.manifests.consolidate.show', 'dashboard.manifests.consolidate.edit')">Por consolidar</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.manifests.consolidated.index')" :active="isRoute('dashboard.manifests.consolidated.index', 'dashboard.manifests.consolidated.show')">Consolidados</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.manifests.cancelled.index')" :active="isRoute('dashboard.manifests.cancelled.index', 'dashboard.manifests.cancelled.show')">Cancelados</sidebar-dropdown-item>
                    </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.consolidated.all.index', 'dashboard.consolidated.all.show', 'dashboard.consolidated.open.index', 'dashboard.consolidated.open.show', 'dashboard.consolidated.open.print', 'dashboard.consolidated.open.create', 'dashboard.consolidated.open.edit', 'dashboard.consolidated.finalize.index', 'dashboard.consolidated.finalize.show', 'dashboard.consolidated.finalize.edit', 'dashboard.consolidated.finish.index', 'dashboard.consolidated.finish.show', 'dashboard.consolidated.cancelled.index', 'dashboard.consolidated.cancelled.show')">
                        <template v-slot:icon>
                            <badge-check-outline class="w-5 h-5"></badge-check-outline>
                        </template>

                        <template v-slot:title>
                            <span>Consolidados</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.consolidated.all.index')" :active="isRoute('dashboard.consolidated.all.index', 'dashboard.consolidated.all.show')">Todos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.consolidated.open.index')" :active="isRoute('dashboard.consolidated.open.index', 'dashboard.consolidated.open.show', 'dashboard.consolidated.open.create', 'dashboard.consolidated.open.edit', 'dashboard.consolidated.open.print')">Abiertos</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.consolidated.finalize.index')" :active="isRoute('dashboard.consolidated.finalize.index', 'dashboard.consolidated.finalize.show', 'dashboard.consolidated.finalize.edit')">Por finalizar</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.consolidated.finish.index')" :active="isRoute('dashboard.consolidated.finish.index', 'dashboard.consolidated.finish.show')">Finalizados</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.consolidated.cancelled.index')" :active="isRoute('dashboard.consolidated.cancelled.index', 'dashboard.consolidated.cancelled.show')">Cancelados</sidebar-dropdown-item>
                    </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.binnacles.coa.index', 'dashboard.binnacles.departures.index')">
                            <template v-slot:icon>
                                <ClipboardListOutline class="w-5 h-5"></ClipboardListOutline>
                            </template>

                            <template v-slot:title>
                                <span>Bitácoras</span>
                            </template>

                            <sidebar-dropdown-item :active="isRoute('dashboard.binnacles.coa.index')" :href="route('dashboard.binnacles.coa.index')">C.O.A.</sidebar-dropdown-item>
                            <sidebar-dropdown-item :active="isRoute('dashboard.binnacles.departures.index')" :href="route('dashboard.binnacles.departures.index')">Salidas</sidebar-dropdown-item>
                        </sidebar-dropdown>

                    <sidebar-dropdown :active="isRoute('dashboard.invoices.all.index', 'dashboard.invoices.all.show', 'dashboard.invoices.prefecture.index', 'dashboard.invoices.prefecture.show', 'dashboard.invoices.prefecture.create', 'dashboard.invoices.prefecture.edit', 'dashboard.invoices.invoiced.index', 'dashboard.invoices.invoiced.show', 'dashboard.invoices.cancelled.index', 'dashboard.invoices.cancelled.show')">
                        <template v-slot:icon>
                            <cash-outline class="w-5 h-5"></cash-outline>
                        </template>

                        <template v-slot:title>
                            <span>Facturas</span>
                        </template>

                        <sidebar-dropdown-item :href="route('dashboard.invoices.all.index')" :active="isRoute('dashboard.invoices.all.index', 'dashboard.invoices.all.show')">Todas</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.invoices.prefecture.index')" :active="isRoute('dashboard.invoices.prefecture.index', 'dashboard.invoices.prefecture.show', 'dashboard.invoices.prefecture.create', 'dashboard.invoices.prefecture.edit')">Prefacturadas</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.invoices.invoiced.index')" :active="isRoute('dashboard.invoices.invoiced.index', 'dashboard.invoices.invoiced.show')">Facturadas</sidebar-dropdown-item>
                        <sidebar-dropdown-item :href="route('dashboard.invoices.cancelled.index')" :active="isRoute('dashboard.invoices.cancelled.index', 'dashboard.invoices.cancelled.show')">Canceladas</sidebar-dropdown-item>
                    </sidebar-dropdown>
                </nav>
            </div>
        </aside>

        <aside v-show="showResponsiveSidebar" class="fixed inset-0 flex flex-row h-full w-full z-10">
            <div class="flex-shrink-0 w-64 bg-white border-r h-full">
                <div class="border-b border-gray-100">
                    <div class="flex items-center">
                        <button class="py-6 px-4" @click="toggleSidebar">
                            <menu-outline class="h-5 w-5"></menu-outline>
                        </button>
                        
                        <div class="py-6 px-4">
                            <inertia-link :href="route('dash')">
                                <img :src="logotipo" alt="logotipo" width="80" height="40">
                            </inertia-link>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col h-full overscroll-y-auto overflow-x-hidden sidebar-scroll">
                    <nav class="flex-1 px-2 py-4 space-y-2">
                        <sidebar-simple-item :href="route('dash')" :active="isRoute('dash')">
                            <template v-slot:icon>
                                <home-outline class="w-5 h-5"></home-outline>
                            </template>

                            <template v-slot:title>
                                <span>Dashboard</span>
                            </template>
                        </sidebar-simple-item>

                        <!-- <sidebar-dropdown :active="isRoute('dashboard.permissions.index', 'dashboard.permissions.create', 'dashboard.permissions.show', 'dashboard.permissions.edit')">
                            <template v-slot:icon>
                                <shield-check-outline class="w-5 h-5"></shield-check-outline>
                            </template>

                            <template v-slot:title>
                                <span>Autorización</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.permissions.index')" :active="isRoute('dashboard.permissions.index', 'dashboard.permissions.create', 'dashboard.permissions.show', 'dashboard.permissions.edit')">Permisos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :inertiaLink="false" :href="route('roles.index')"><template #content>Roles</template></sidebar-dropdown-item>
                        </sidebar-dropdown> -->

                        <sidebar-dropdown :active="isRoute('dashboard.numbersun.index', 'dashboard.numbersun.show', 'dashboard.numbersun.create', 'dashboard.numbersun.edit', 'dashboard.transportnames.index', 'dashboard.transportnames.show', 'dashboard.transportnames.create', 'dashboard.transportnames.edit', 'dashboard.cities.index', 'dashboard.cities.show', 'dashboard.cities.create', 'dashboard.cities.edit', 'dashboard.generics.index', 'dashboard.generics.show', 'dashboard.generics.create', 'dashboard.generics.edit', 'dashboard.subsidiaries.index', 'dashboard.subsidiaries.show', 'dashboard.subsidiaries.create', 'dashboard.subsidiaries.edit', 'dashboard.authorizations.index', 'dashboard.authorizations.show', 'dashboard.authorizations.create', 'dashboard.authorizations.edit')">
                            <template v-slot:icon>
                                <globe-outline class="w-5 h-5"></globe-outline>
                            </template>

                            <template v-slot:title>
                                <span v-if="$page.props.auth.user.subsidiary.id == 1">Hermosillo</span>
                                <span v-if="$page.props.auth.user.subsidiary.id == 2">Nogales</span>
                                <span v-if="$page.props.auth.user.subsidiary.id == 4">Mexicali</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.authorizations.index')" :active="isRoute('dashboard.authorizations.index', 'dashboard.authorizations.show', 'dashboard.authorizations.create', 'dashboard.authorizations.edit')">
                                Autorizaciones
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.cities.index')" :active="isRoute('dashboard.cities.index', 'dashboard.cities.show', 'dashboard.cities.create', 'dashboard.cities.edit')">
                                Ciudades
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :inertiaLink="false" :href="route('employees.index')">
                                <template #content>Empleados</template>
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.generics.index')" :active="isRoute('dashboard.generics.index', 'dashboard.generics.show', 'dashboard.generics.create', 'dashboard.generics.edit')">
                                Genericos
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.transportnames.index')" :active="isRoute('dashboard.transportnames.index', 'dashboard.transportnames.show', 'dashboard.transportnames.create', 'dashboard.transportnames.edit')">
                                Nombres de transporte
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.numbersun.index')" :active="isRoute('dashboard.numbersun.index', 'dashboard.numbersun.show', 'dashboard.numbersun.create', 'dashboard.numbersun.edit')">
                                Números U.N.
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.subsidiaries.index')" :active="isRoute('dashboard.subsidiaries.index', 'dashboard.subsidiaries.show', 'dashboard.subsidiaries.create', 'dashboard.subsidiaries.edit')">
                                Sucursales
                            </sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.carriers.index', 'dashboard.carriers.show', 'dashboard.carriers.create', 'dashboard.carriers.edit', 'dashboard.recipients.index', 'dashboard.recipients.show', 'dashboard.recipients.create', 'dashboard.recipients.edit')">
                            <template v-slot:icon>
                                <puzzle-outline class="w-5 h-5"></puzzle-outline>
                            </template>

                            <template v-slot:title>
                                <span>Proveedores</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.carriers.index')" :active="isRoute('dashboard.carriers.index', 'dashboard.carriers.show', 'dashboard.carriers.create', 'dashboard.carriers.edit')">Transportistas</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.recipients.index')" :active="isRoute('dashboard.recipients.index', 'dashboard.recipients.show', 'dashboard.recipients.create', 'dashboard.recipients.edit')">Destinatarios</sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.wastes.index', 'dashboard.wastes.create', 'dashboard.wastes.show', 'dashboard.wastes.edit','dashboard.agreements.index', 'dashboard.agreements.create', 'dashboard.agreements.show', 'dashboard.agreements.edit', 'dashboard.clients.index', 'dashboard.clients.create', 'dashboard.clients.show', 'dashboard.clients.edit')">
                            <template v-slot:icon>
                                <user-group-outline class="w-5 h-5"></user-group-outline>
                            </template>

                            <template v-slot:title>
                                <span>Clientes</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.clients.index')" :active="isRoute('dashboard.clients.index', 'dashboard.clients.create', 'dashboard.clients.show', 'dashboard.clients.edit')">Clientes</sidebar-dropdown-item>
                            <sidebar-dropdown-item :inertiaLink="false" :href="route('generators.index')">
                                <template #content>Generadores</template>
                            </sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.wastes.index')" :active="isRoute('dashboard.wastes.index', 'dashboard.wastes.create', 'dashboard.wastes.show', 'dashboard.wastes.edit')">Residuos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.representatives.index')" :active="isRoute('dashboard.representatives.index', 'dashboard.representatives.create', 'dashboard.representatives.show', 'dashboard.representatives.edit')">Representantes amb</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.agreements.index')" :active="isRoute('dashboard.agreements.index', 'dashboard.agreements.create', 'dashboard.agreements.show', 'dashboard.agreements.edit')">Contratos</sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.manifests.consolidate.index', 'dashboard.manifests.consolidate.show', 'dashboard.manifests.consolidate.edit', 'dashboard.manifests.consolidated.index', 'dashboard.manifests.consolidated.show', 'dashboard.manifests.cancelled.index', 'dashboard.manifests.cancelled.show', 'dashboard.manifests.open.index', 'dashboard.manifests.open.show', 'dashboard.manifests.open.create', 'dashboard.manifests.open.edit', 'dashboard.manifests.all.index', 'dashboard.manifests.all.show', 'dashboard.manifests.open.print')">
                            <template v-slot:icon>
                                <badge-check-outline class="w-5 h-5"></badge-check-outline>
                            </template>

                            <template v-slot:title>
                                <span>Manifiestos</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.manifests.all.index')" :active="isRoute('dashboard.manifests.all.index', 'dashboard.manifests.all.show')">Todos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.manifests.open.index')" :active="isRoute('dashboard.manifests.open.index', 'dashboard.manifests.open.show', 'dashboard.manifests.open.create', 'dashboard.manifests.open.edit', 'dashboard.manifests.open.print')">Abiertos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.manifests.consolidate.index')" :active="isRoute('dashboard.manifests.consolidate.index', 'dashboard.manifests.consolidate.show', 'dashboard.manifests.consolidate.edit')">Por consolidar</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.manifests.consolidated.index')" :active="isRoute('dashboard.manifests.consolidated.index', 'dashboard.manifests.consolidated.show')">Consolidados</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.manifests.cancelled.index')" :active="isRoute('dashboard.manifests.cancelled.index', 'dashboard.manifests.cancelled.show')">Cancelados</sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.consolidated.all.index', 'dashboard.consolidated.all.show', 'dashboard.consolidated.open.index', 'dashboard.consolidated.open.show', 'dashboard.consolidated.open.create', 'dashboard.consolidated.open.edit', 'dashboard.consolidated.open.print', 'dashboard.consolidated.finalize.index', 'dashboard.consolidated.finalize.show', 'dashboard.consolidated.finalize.edit', 'dashboard.consolidated.finish.index', 'dashboard.consolidated.finish.show', 'dashboard.consolidated.cancelled.index', 'dashboard.consolidated.cancelled.show')">
                            <template v-slot:icon>
                                <badge-check-outline class="w-5 h-5"></badge-check-outline>
                            </template>

                            <template v-slot:title>
                                <span>Consolidados</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.consolidated.all.index')" :active="isRoute('dashboard.consolidated.all.index', 'dashboard.consolidated.all.show')">Todos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.consolidated.open.index')" :active="isRoute('dashboard.consolidated.open.index', 'dashboard.consolidated.open.show', 'dashboard.consolidated.open.create', 'dashboard.consolidated.open.edit', 'dashboard.consolidated.open.print')">Abiertos</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.consolidated.finalize.index')" :active="isRoute('dashboard.consolidated.finalize.index', 'dashboard.consolidated.finalize.show', 'dashboard.consolidated.finalize.edit')">Por finalizar</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.consolidated.finish.index')" :active="isRoute('dashboard.consolidated.finish.index', 'dashboard.consolidated.finish.show')">Finalizados</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.consolidated.cancelled.index')" :active="isRoute('dashboard.consolidated.cancelled.index', 'dashboard.consolidated.cancelled.show')">Cancelados</sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.binnacles.coa.index','dashboard.binnacles.departures.index')">
                            <template v-slot:icon>
                                <ClipboardListOutline class="w-5 h-5"></ClipboardListOutline>
                            </template>

                            <template v-slot:title>
                                <span>Bitácoras</span>
                            </template>

                            <sidebar-dropdown-item :active="isRoute('dashboard.binnacles.coa.index')" :href="route('dashboard.binnacles.coa.index')">C.O.A.</sidebar-dropdown-item>
                            <sidebar-dropdown-item :active="isRoute('dashboard.binnacles.departures.index')" :href="route('dashboard.binnacles.departures.index')">Salidas</sidebar-dropdown-item>
                        </sidebar-dropdown>

                        <sidebar-dropdown :active="isRoute('dashboard.invoices.all.index', 'dashboard.invoices.all.show', 'dashboard.invoices.prefecture.index', 'dashboard.invoices.prefecture.show', 'dashboard.invoices.prefecture.create', 'dashboard.invoices.prefecture.edit', 'dashboard.invoices.invoiced.index', 'dashboard.invoices.invoiced.show', 'dashboard.invoices.cancelled.index', 'dashboard.invoices.cancelled.show')">
                            <template v-slot:icon>
                                <cash-outline class="w-5 h-5"></cash-outline>
                            </template>

                            <template v-slot:title>
                                <span>Facturas</span>
                            </template>

                            <sidebar-dropdown-item :href="route('dashboard.invoices.all.index')" :active="isRoute('dashboard.invoices.all.index', 'dashboard.invoices.all.show')">Todas</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.invoices.prefecture.index')" :active="isRoute('dashboard.invoices.prefecture.index', 'dashboard.invoices.prefecture.show', 'dashboard.invoices.prefecture.create', 'dashboard.invoices.prefecture.edit')">Prefacturadas</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.invoices.invoiced.index')" :active="isRoute('dashboard.invoices.invoiced.index', 'dashboard.invoices.invoiced.show')">Facturadas</sidebar-dropdown-item>
                            <sidebar-dropdown-item :href="route('dashboard.invoices.cancelled.index')" :active="isRoute('dashboard.invoices.cancelled.index', 'dashboard.invoices.cancelled.show')">Canceladas</sidebar-dropdown-item>
                        </sidebar-dropdown>
                    </nav>
                </div>
            </div>

            <div class="flex-1 w-full h-full bg-gray-800 bg-opacity-70 cursor-pointer" @click="toggleSidebar"></div>
        </aside>
    </div>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import SidebarDropdown      from '@/Layouts/Sidebar/Dropdown'
    import SidebarDropdownItem  from '@/Layouts/Sidebar/DropdownItem'
    import SidebarSimpleItem    from '@/Layouts/Sidebar/SimpleItem'
    import HomeOutline          from '@/Components/Icons/HeroIcons/Outline/Home'
    import PuzzleOutline        from '@/Components/Icons/HeroIcons/Outline/Puzzle'
    import UserGroupOutline     from '@/Components/Icons/HeroIcons/Outline/UserGroup'
    import GlobeOutline         from '@/Components/Icons/HeroIcons/Outline/Globe'
    import ShieldCheckOutline   from '@/Components/Icons/HeroIcons/Outline/ShieldCheck'
    import BadgeCheckOutline    from '@/Components/Icons/HeroIcons/Outline/BadgeCheck'
    import ClipboardListOutline from '@/Components/Icons/HeroIcons/Outline/ClipboardList'
    import MenuOutline          from '@/Components/Icons/HeroIcons/Outline/Menu'
    import CashOutline          from '@/Components/Icons/HeroIcons/Outline/Cash'

    export default {
        components: {
            SidebarDropdown,
            SidebarDropdownItem,
            SidebarSimpleItem,
            InertiaLink,
            HomeOutline,
            PuzzleOutline,
            UserGroupOutline,
            GlobeOutline,
            ShieldCheckOutline,
            BadgeCheckOutline,
            ClipboardListOutline,
            MenuOutline,
            CashOutline
        },
        props: ['show'],
        data() {
            return {
                logotipo: process.env.APP_URL + '/storage/brand/new_logotipo.svg',
                showSidebar: this.show,
                showResponsiveSidebar: window.innerWidth >= 1024 ? false : true,
            }
        },
        emits: ['toggleSidebar'],
        methods: {
            watchScreen() {
                if (window.innerWidth <= 1024)      this.showResponsiveSidebar = true
                else if (window.innerWidth >= 1024) this.showResponsiveSidebar = false
            },
            toggleSidebar() {
                this.setSidebar(!this.getSidebar())
                this.$emit('toggleSidebar')
            },
            getSidebar() {
                if (window.localStorage.getItem('show-sidebar')) return JSON.parse(window.localStorage.getItem('show-sidebar'))
                return false;
            },
            setSidebar: (value) => window.localStorage.setItem('show-sidebar', value)
        },
        created() {
            window.addEventListener("resize", this.watchScreen)
        },
        destroyed() {
            window.removeEventListener("resize", this.watchScreen)
        },
        watch: {
            show(value) {
                this.showSidebar = value
            }
        }
    }
</script>