<template>
    <inertia-link 
        v-if="inertiaLink"
        :href="href"
        role="menuitem"
        class="block p-2 text-sm text-gray-400 transition-colors duration-200 rounded-md hover:text-gray-700"
        :class="{'text-gray-700' : active}">
        <slot></slot>
    </inertia-link>

    <a v-else
        :href="href"
        role="menuitem"
        class="block p-2 text-sm text-gray-400 transition-colors duration-200 rounded-md hover:text-gray-700"
        :class="{'text-gray-700' : active}">
        <slot name="content"></slot>
    </a>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        props: {
            href: {
                type: String,
                default: '#'
            },
            active: {
                type: Boolean,
                default: false
            },
            inertiaLink: {
                type: Boolean,
                default: true
            }
        },
        components: {
            InertiaLink
        }
    }
</script>