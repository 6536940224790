<template>
    <div class="py-80">
        <div class="flex flex-col">
            <div class="font-bold text-5xl">
                <span ref="typedElement"></span>
            </div>
            <div class="font-medium text-2xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam illo laborum modi eum soluta earum voluptatem? A esse quibusdam obcaecati, id maiores ducimus modi harum nisi! Quod odit ab necessitatibus.
            </div>
        </div>
    </div>

    <div class="flex flex-col">
        <div class="flex items-center font-bold text-5xl">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-6 w-6 mr-2 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
            </svg>
            Blog
        </div>
        <div class="font-medium text-2xl">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam illo laborum modi eum soluta earum voluptatem? A esse quibusdam obcaecati, id maiores ducimus modi harum nisi! Quod odit ab necessitatibus.
        </div>
        
        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mx-auto mt-4">
            <div v-for="(post, index) in posts.data" :key="index">
                <div class="col-span-1">
                    <div class=" py-4 bg-white dark:bg-gray-800 shadow rounded-lg">
                        <div v-if="post?.from" class="flex mb-4 px-4">
                            <img class="w-12 h-12 rounded-full" src="/images/new_isotipo.svg"/>
                            <div class="ml-2 mt-0.5">
                                <span class="block font-medium text-base leading-snug text-black dark:text-gray-100">{{post.from?.name}}</span>
                                <span class="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">{{moment(post.created_time)}}</span>
                            </div>
                        </div>

                        <p class="text-gray-800 dark:text-gray-100 leading-snug md:leading-normal text-sm px-4" :class="post.message?.length>=150 ? 'truncate' : ''">{{post?.message}}</p>
                        
                        <div class="py-2 flex justify-center">
                            <div v-for="(data, index) in post.attachments?.data" :key="index">
                                <div class="relative">
                                    <div class="absolute right-0 text-white m-1">
                                        <div class="px-3 bg-gray-600 rounded-full text-xs">
                                            1/<span v-if="data?.subattachments?.data">1</span><span v-else-if="data?.subattachments?.data.length > 0">{{data?.subattachments?.data.length}}</span>
                                        </div>
                                    </div>
                                </div>
                                <img :src="data['media'].image.src" alt="" class="object-cover h-96 w-auto">
                            </div>
                        </div>

                        <div class="flex justify-between items-center mt-3 space-y-2 md:space-y-0 px-4">
                            <div class="flex">
                                <svg class="p-0.5 h-6 w-6 rounded-full z-20 bg-white dark:bg-gray-800 " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'><defs><linearGradient id='a1' x1='50%' x2='50%' y1='0%' y2='100%'><stop offset='0%' stop-color='#18AFFF'/><stop offset='100%' stop-color='#0062DF'/></linearGradient><filter id='c1' width='118.8%' height='118.8%' x='-9.4%' y='-9.4%' filterUnits='objectBoundingBox'><feGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/><feOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/><feComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/><feColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/></filter><path id='b1' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/></defs><g fill='none'><use fill='url(#a1)' xlink:href='#b1'/><use fill='black' filter='url(#c1)' xlink:href='#b1'/><path fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/></g></svg>
                                <span class="ml-1 text-gray-500 dark:text-gray-400  font-light">{{post.likes.summary.total_count + post.reactions.summary.total_count}}</span>
                            </div>
                            <div class="ml-1 text-gray-500 dark:text-gray-400 font-light">
                                <a :href="post.permalink_url" target="_blank">Ver publicacion completa en facebook</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-4 mx-auto my-auto">
            <orange-btn>Ver mas publicaciones</orange-btn>
        </div>
    </div>
</template>

<script>
    import { Head, Link }    from '@inertiajs/inertia-vue3'
    import GuestAppLayout    from '@/Layouts/Guest.vue'
    import moment            from 'moment'
    import Typed             from 'typed.js'
    import OrangeBtn         from '@/Components/Buttons/Orange'

    export default {
        layout: GuestAppLayout,
        components: {
            Head,
            Link,
            OrangeBtn
        },
        data() {
            return {
                posts: [],
                typing: ''
            }
        },
        methods: {
            async init() {
                const response  = await axios.get(`${process.env.FACEBOOK_URL}/${process.env.FACEBOOK_ID}/feed?fields=id%2Cmessage%2Cpermalink_url%2Ccreated_time%2Cparent_id%2Cis_hidden%2Cattachments%2Cfrom%2Cshares%2Ctarget%2Cto%2Ccomments.summary(true)%2Clikes.summary(true)%2Creactions.summary(true)%2Cmessage_tags&limit=8&access_token=${process.env.FACEBOOK_TOKEN}`)
                this.posts      = response.data
                console.log(this.posts.data)
            },
            moment(date) {
                return moment(date).locale('es').format('DD [de] MMMM [a las] h:mm')
            },
            initTyping() {
                new Typed(this.$refs.typedElement, {
                    strings: ["Reduce", "Reusa", "Reinventa", "Recicla"],
                    typeSpeed: 120,
                    backSpeed:60,
                    loop: true,
                    loopCount: Infinity,
                    smartBackspace: true
                })
            }
        },
        mounted() {
            this.init()
            this.initTyping()
        }
    }
</script>