<template>
    <inertia-link :href="href" :class="classes">
        <slot></slot>
    </inertia-link>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'
    
    export default {
        props: ['href', 'active'],
        components: {
            InertiaLink
        },
        computed: {
            classes() {
                return this.active
                            ? 'inline-flex items-center px-3 py-4 border-b-2 border-orange-400 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-orange-700 transition'
                            : 'inline-flex items-center px-3 py-4 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition'
            }
        }
    }
</script>
