<template>
    <span class="w-full flex flex-row justify-between items-center px-3 py-2 bg-white shadow-sm sm:text-sm border border-gray-300 rounded-md cursor-pointer disabled:opacity-25">
        <span>{{fileName ? fileName : placeholder}}</span>
        <input @change="onSetFile" ref="file" type="file" class="hidden" :accept="accept" :multiple="multiple" :disabled="disabled">
        <Spin v-show="isLoading" class="text-gray-400 h-4 w-4"></Spin>
    </span>
</template>

<script>
    import Spin from '@/Components/Loaders/Spin'

    export default {
        components: {
            Spin
        },
        props: {
            accept:                 {type: String,  default: 'application/pdf'},
            placeholder:            {type: String,  default: 'Seleccione un archivo'},
            disabled:               {type: Boolean, default: false},
            multiple:               {type: Boolean, default: false},
            open:                   {type: Boolean, default: false},
        },
        data() {
            return {
                file:               null,
                fileName:           null,
                isLoading:          false,
            }
        },
        emits: ['closeFileModa', 'returnFile'],
        methods: {
            onSetFile(event) {
                if (!this.disabled && event.target.files.length) {
                    this.file       = event.target.files[0]
                    this.fileName   = event.target.files[0].name
                    this.$emit('returnFile', this.file)
                }
            },
            onFocus() {
                if (this.open) {
                    this.isLoading = false
                    this.$emit('closeFileModal', false)
                }
            }
        },
        created() {
            window.addEventListener('focus', this.onFocus)
        },  
        unmounted() {
            window.removeEventListener('focus', this.onFocus)
        },
        watch: {
            $props: {
                deep: true,
                handler(prop) {
                    if (!prop.disabled) {
                        if (prop.open) {
                            this.isLoading = true
                            this.$refs.file.click()
                        }
                    }
                }
            }
        }
    }
</script>