<template>
    <div class="container max-w-full mx-auto py-24 px-6">
        <div class="font-sans">
            <div class="max-w-sm mx-auto px-6">
                <div class="relative flex flex-wrap">
                    <div class="w-full relative">
                        <div class="mt-6">
                            <div class="mb-5 pb-1border-b-2 text-center font-base text-gray-700">
                                <img :src="imagotipo" alt="imagotipo">
                            </div>

                            <form class="mt-8" autocomplete="off" @submit.prevent="submit">
                                <div class="mx-auto max-w-lg">
                                    <div class="py-2">
                                        <span class="px-1 mb-1 text-sm font-bold">Correo electrónico</span>
                                        <input v-model="form.email" :disabled="form.processing" :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" type="text" autocomplete="off" aria-autocomplete="off"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-sm focus:placeholder-gray-500 focus:bg-white focus:border-orange-600 focus:outline-none focus:ring-transparent">
                                        <span
                                            v-if="form.errors.email"
                                            class="text-red-500 text-xs font-semibold mt-1 px-1">{{ form.errors.email }}</span>
                                    </div>
                                    <div class="py-2">
                                        <span class="px-1 mb-1 text-sm font-bold">Contraseña</span>
                                        <div class="relative">
                                            <input v-model="form.password" :disabled="form.processing" :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" autocomplete="current-password" :type="show ? 'text' : 'password'" class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-sm focus:placeholder-gray-500 focus:bg-white focus:border-orange-600 focus:outline-none focus:ring-transparent">
                                            <div @click="toggleShow" class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">

                                                <eye-icon v-if="show" class="w-5 text-blue-500"></eye-icon>

                                                <eye-off-icon v-else class="w-5 text-gray-400"></eye-off-icon>

                                            </div>
                                        </div>
                                        <div
                                            v-if="form.errors.password"
                                            class="text-red-500 text-xs font-semibold mt-1 px-1">{{ form.errors.password }}</div>
                                    </div>
                                    <div class="flex items-start my-4">
                                        <div class="flex items-center h-5">
                                            <input
                                                id="remember"
                                                type="checkbox"
                                                :disabled="form.processing"
                                                v-model="form.remember"
                                                class="focus:ring-transparent h-4 w-4 text-orange-600 border-2 border-gray-300 rounded"
                                                :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        </div>
                                        <div class="ml-3 text-sm">
                                            <label for="remember" class="py-2 text-sm font-bold text-gray-700 leading-snug">Recordarme</label>
                                        </div>
                                    </div>
                                    <button :disabled="form.processing" type="submit" class="mt-3 text-lg font-semibold bg-blue-600 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-blue-500">
                                        Iniciar sesión
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EyeIcon from '@/Components/Icons/HeroIcons/outline/Eye'
    import EyeOffIcon from '@/Components/Icons/HeroIcons/outline/EyeOff'

    export default {
        components: {
            EyeIcon,
            EyeOffIcon
        },
        data() {
            return {
                imagotipo:  process.env.APP_URL + '/storage/brand/new_imagotipo.svg',
                loading:    true,
                isDark:     false,
                show:       false,
                form:       this.$inertia.form({
                    email:      null,
                    password:   null,
                    remember:   false
                })
            }
        },
        methods: {
            getTheme() {
                if (window.localStorage.getItem('dark')) {
                    return JSON.parse(window.localStorage.getItem('dark'))
                }
                return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            },
            setTheme(value) {
                window.localStorage.setItem('dark', value)
            },
            toggleShow() {
                this.show = !this.show
            },
            submit() {
                this.form.post(route('auth.dashboard.employees.login'), this.form, {preserveScroll: true, forceFormData: true})
            }
        }
    }
</script>