require('./bootstrap');

import oruga                from '@oruga-ui/oruga-next';
import { library }          from '@fortawesome/fontawesome-svg-core'
import {
    faCheck,
    faCheckCircle,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faArrowUp,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretUp,
    faChevronUp,
    faChevronDown,
    faSearch,
    faAngleUp,
    faCircleNotch,
    faCircleQuestion
  }                         from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'

library.add(
    faCheck,
    faCheckCircle,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faArrowUp,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretUp,
    faChevronUp,
    faChevronDown,
    faSearch,
    faAngleUp,
    faCircleNotch,
    faCircleQuestion
)
import { createApp, h }     from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress }  from '@inertiajs/progress';
import '@oruga-ui/oruga-next/dist/oruga-full.min.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title)  => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(oruga, {
                iconComponent:  'vue-fontawesome',
                iconPack:       'fas'
            })
            .mixin({ methods: { route, isRoute(...routes) {
                return routes.some(route => this.route().current(route));
            } } })
            .mount(el);
    },
});

InertiaProgress.init({
    color: '#FF8B42',
    includeCSS: true,
    showSpinner: false,
});