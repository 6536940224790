<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.carriers.index')">
                        Transportistas
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.carriers.show', carrier.id)">
                        {{carrier.data.full_name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-6">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre del transportista</label>
                                <input 
                                    id="name"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-1 mt-2">{{ form.errors.name }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label 
                                    for="comment"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Comentario</label>
                                <input 
                                    id="comment"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.comment"
                                    autocomplete="givencomment"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.comment"
                                    class="text-red-500 text-xs mt-1 mt-2">{{ form.errors.comment }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="phone" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Teléfono</label>
                                <input 
                                    id="phone"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.phone"
                                    autocomplete="givenphone"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.phone"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.phone }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="fax" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">F.A.X.</label>
                                <input 
                                    id="fax"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.fax"
                                    autocomplete="givenfax"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.fax"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.fax }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                                <label 
                                    for="rfc" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">R.F.C.</label>
                                <input 
                                    id="rfc"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.rfc"
                                    autocomplete="givenrfc"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.rfc"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.rfc }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 lg:col-span-8">
                                <label 
                                    for="address"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Dirección</label>
                                <input 
                                    id="address"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.address"
                                    autocomplete="givenaddress"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.address" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.address }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-2">
                                <label 
                                    for="numInt"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nº Interior</label>
                                <input 
                                    id="numInt"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.numInt"
                                    autocomplete="givennumInt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.numInt" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.numInt }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-2">
                                <label 
                                    for="numExt"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nº Exterior</label>
                                <input 
                                    id="numExt"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.numExt"
                                    autocomplete="givennumExt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.numExt"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.numExt }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-6 lg:col-span-3">
                                <label 
                                    for="state" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Estado</label>

                                <selectize
                                    :options="states"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un estado.'"
                                    @input="stateSelected"
                                    :value="setState"></selectize>
                                <div
                                    v-if="form.errors.state" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.state }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-3">
                                <label 
                                    for="city" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Ciudad</label>

                                <selectize
                                    :options="cities"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una ciudad.'"
                                    @input="citySelected"
                                    :value="setCity"
                                    :busy="busyCities"></selectize>
                                <div
                                    v-if="form.errors.city"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.city }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-3">
                                <label 
                                    for="postalCode"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Código postal</label>
                                <input 
                                    id="postalCode"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.postalCode"
                                    autocomplete="givenpostalCode"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.postalCode" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.postalCode }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-3">
                                <label 
                                    for="typeWaste" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Tipo de residuos</label>

                                <selectize
                                    :options="typeWastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de residuo.'"
                                    @input="typeWasteSelected"
                                    :value="setTypeWaste"></selectize>
                                <div
                                    v-if="form.errors.typeWaste" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-if="form.typeWaste == 1 || form.typeWaste == 3" class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="semarnatPermit" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Permiso de Semarnat</label>
                                <input 
                                    id="semarnatPermit"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.semarnatPermit"
                                    autocomplete="givensemarnatPermit"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />                                
                                <div
                                    v-if="form.errors.semarnatPermit" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.semarnatPermit }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="semarnatValidity" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha de vigencia</label>
                                <input 
                                    id="semarnatValidity"
                                    type="Date"
                                    :disabled="form.processing"
                                    v-model="form.semarnatValidity"
                                    autocomplete="givensemarnatValidity"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.semarnatValidity" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.semarnatValidity }}</div>
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label 
                                    for="sctPermit"
                                    class="block text-sm font-semibold text-gray-700 mb-1">S.C.T.</label>
                                <input 
                                    id="sctPermit"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.sctPermit"
                                    autocomplete="givensctPermit"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.sctPermit" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.sctPermit }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-if="form.typeWaste == 2 || form.typeWaste == 3" class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-1 md:col-span-6">
                                <label 
                                    for="laiPermit" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">L.A.I.</label>
                                <input 
                                    id="laiPermit"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.laiPermit"
                                    autocomplete="givenlaiPermit"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />                                
                                <div
                                    v-if="form.errors.laiPermit" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.laiPermit }}</div>
                            </div>

                            <div class="col-span-1 md:col-span-6">
                                <label 
                                    for="laiValidity" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha de vigencia</label>
                                <input 
                                    id="laiValidity"
                                    type="date"
                                    :disabled="form.processing"
                                    v-model="form.laiValidity"
                                    autocomplete="givenlaiValidity"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.laiValidity" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.laiValidity }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Sede</label>
                                <selectize
                                    :options="subsidiaries"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una sede.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary"
                                    :label="'descriptive_name'" :keys="['descriptive_name', 'id']"></selectize>
                                <div
                                    v-if="form.errors.subsidiary"
                                    class="text-red-500 text-xs mt-1 mt-2">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Representante ambiental</h4>
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 lg:col-span-4">
                                <label 
                                    for="representativename"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input
                                    id="representativename"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.representative.name"
                                    autocomplete="givenrepresentativename"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.representative" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.representative }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="representativeemail"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Correo electrónico</label>
                                <input
                                    id="representativeemail"
                                    type="email"
                                    :disabled="form.processing"
                                    v-model="form.representative.email"
                                    autocomplete="givenrepresentativeemail"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.representative"
                                    class="text-red-500 text-xs mt-1">{{form.errors.representative}}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label
                                    for="representativemovil"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Móvil</label>
                                <input
                                    id="representativemovil"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.representative.movil"
                                    autocomplete="givenrepresentativemovil"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.representative"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.representative }}</div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.carriers.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Actualizar
                    </btn-warning>
                </div>

            </form>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'
    import moment           from 'moment'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            Selectize,
            Spin
        },
        props: ['carrier', 'subsidiaries', 'states'],
        data() {
            return {
                form: this.$inertia.form({
                    name:                       null,
                    comment:                    null,
                    phone:                      null,
                    fax:                        null,
                    rfc:                        null,
                    address:                    null,
                    numInt:                     null,
                    numExt:                     null,
                    state:                      null,
                    city:                       null,
                    postalCode:                 null,
                    typeWaste:                  null,
                    semarnatPermit:             null,
                    semarnatValidity:           null,
                    sctPermit:                  null,
                    laiPermit:                  null,
                    laiValidity:                null,
                    subsidiary:                 null,
                    representative: {
                        name:   null,
                        email:  null,
                        movil:  null
                    }
                }),
                typeWastes:                     [{id: 1, name: 'Peligrosos'}, {id: 2, name: 'No peligrosos'}, {id: 3, name: 'Peligrosos y no peligrosos'}],
                cities:                         [],
                setState:                       null,
                setCity:                        null,
                setTypeWaste:                   null,
                setSubsidiary:                  null,
                busyCities:                     false,
                firstTime:                      true
            }
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.carriers.update', this.carrier.id), {preserveScroll: true});
            },
            init() {
                this.form.name                  = this.carrier.data.name
                this.form.comment               = this.carrier.data.comment
                this.form.phone                 = this.carrier.data.phone
                this.form.fax                   = this.carrier.data.fax
                this.form.rfc                   = this.carrier.data.rfc
                this.form.address               = this.carrier.data.address
                this.form.numInt                = this.carrier.data.num_int
                this.form.numExt                = this.carrier.data.num_ext
                this.setState                   = parseInt(this.carrier.data.state)
                this.stateSelected(parseInt(this.carrier.data.state))
                this.form.postalCode            = this.carrier.data.postal_code
                this.form.typeWaste             = this.carrier.data.type_waste == 'Peligrosos' ? 1 : (this.carrier.data.type_waste == 'No peligrosos' ? 2 : 3)
                this.setTypeWaste               = this.form.typeWaste

                if (this.carrier.data.type_waste == 'Peligrosos' || this.carrier.data.type_waste == 'Peligrosos y no peligrosos') {
                    this.form.semarnatPermit      = this.carrier.data.semarnat_permit
                    this.form.semarnatValidity    = this.carrier.data?.semarnat_validity ? moment(this.carrier.data.semarnat_validity.substring(0, 10)).format('YYYY-MM-DD') : null
                    this.form.sctPermit           = this.carrier.data.sct_permit
                } 
                
                if (this.carrier.data.type_waste == 'No peligrosos' || this.carrier.data.type_waste == 'Peligrosos y no peligrosos') {
                    this.form.laiPermit           = this.carrier.data.lai_permit
                    this.form.laiValidity         = this.carrier.data?.lai_validity ? moment(this.carrier.data.lai_validity.substring(0, 10)).format('YYYY-MM-DD') : null
                }
                
                this.form.subsidiary            = this.carrier.subsidiary_id
                this.setSubsidiary              = this.carrier.subsidiary_id
                this.form.representative.name   = this.carrier.representative.name
                this.form.representative.email  = this.carrier.representative.email
                this.form.representative.movil  = this.carrier.representative.mobile
            },
            async stateSelected(val) {
                this.busyCities     = true
                this.form.city      = null
                this.setCity        = -1
                if (val) {
                    this.form.state = typeof val == 'object' ? val.id : val
                    this.cities     = []
                    const cities    = await axios.post(route('municipalities.getMunicipalitiesByState'), {id: this.form.state})
                    this.cities     = cities.data.municipalities
                    
                } else {
                    this.form.state = null
                    this.cities     = []
                }
                this.busyCities     = false
            },
            citySelected(val) {
                this.form.city          = val ? val.id : null
            },
            typeWasteSelected(val) {
                this.form.typeWaste     = val ? val.id : null
            },
            subsidiarySelected(val) {
                this.form.subsidiary    = val ? val.id : null
            }
        },
        updated() {
            this.setState           = this.form.state       ? this.form.state           : -1
            this.setTypeWaste       = this.form.typeWaste   ? this.form.typeWaste       : -1
            this.setCity            = this.form.city        ? this.form.city            : -1
            this.setSubsidiary      = this.form.subsidiary  ? this.form.subsidiary      : -1
        },
        mounted() {
            this.init()
        },
        watch: {
            cities: {
                deep: true, 
                handler(data) {
                    if (data.length && this.firstTime) {
                        this.form.city             = parseInt(this.carrier.data.city)
                        this.setCity               = parseInt(this.carrier.data.city)
                        this.firstTime             = false
                    }
                }
            }
        }
    }
</script>