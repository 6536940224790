<template>
    <inertia-link 
        v-if="inertiaLink"
        :href="href"
        class="flex items-center p-2 text-gray-500 transition-colors rounded-md dark:text-light hover:bg-blue-300 hover:text-gray-800"
        :class="{'bg-blue-300 text-gray-800': active}" 
        role="button">

        <span aria-hidden="true">
            <slot name="icon"></slot>
        </span>

        <span class="ml-2 text-sm">
            <slot name="title"></slot>
        </span>
    </inertia-link>

    <a v-else
        :href="href"
        class="flex items-center p-2 text-gray-500 transition-colors rounded-md dark:text-light hover:bg-blue-300 hover:text-gray-800"
        :class="{'bg-blue-300 text-gray-800': active}" 
        role="button">
        <span aria-hidden="true">
            <slot name="icono"></slot>
        </span>

        <span class="ml-2 text-sm">
            <slot name="content"></slot>
        </span>
    </a>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        props: {
            href: {
                type: String,
                default: '#'
            },
            active: {
                type: Boolean,
                default: false
            },
            inertiaLink: {
                type: Boolean,
                default: true
            }
        },
        components: {
            InertiaLink
        }
    }
</script>