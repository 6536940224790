<template>
    <button 
        :type="type" 
        :disabled="disabled" 
        :class="disabled===true ? 'cursor-not-allowed' : 'cursor-pointer'" 
        class="inline-flex items-center px-4 py-2 bg-blue-500 border border-blue-500 sm:rounded-md font-semibold text-xs text-white uppercase tracking-widest select-none hover:bg-blue-600 active:bg-blue-600 focus:outline-none focus:shadow-outline focus:border-blue-600 focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>