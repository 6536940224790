<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.consolidated.open.index')">
                        Consolidados abiertos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.consolidated.open.show', consolidated.id)">
                        {{consolidated.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Imprimiendo
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div id="printMe" class="bg-white overflow-hidden shadow sm:rounded">

                    <div v-if="this.$page.props.auth.user.subsidiary.id == 1">
                        <HMOPrintConsolidated :wastes="wastes" :folios="folios" :consolidated="consolidated" :state="state" :municipality="municipality" :stateCarrier="stateCarrier" :municipalityCarrier="municipalityCarrier"></HMOPrintConsolidated>
                    </div>

                </div>
            </div>

            <div class="col-span-1">
                <div class="text-right space-x-4">
                    <link-light :href="route('dashboard.consolidated.open.index')">Cancelar</link-light>
                    <btn-success @click="print">
                        Imprimir
                    </btn-success>
                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }        from '@inertiajs/inertia-vue3'
    import AppLayout              from '@/Layouts/AppLayout.vue'
    import BreadcumbNav           from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem          from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid              from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight              from '@/Components/Links/Light'
    import BtnSuccess             from '@/Components/Buttons/Success'
    import HMOPrintConsolidated   from '@/Components/Prints/HMO/Consolidated'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            HMOPrintConsolidated
        },
        props: ['consolidated', 'state', 'municipality', 'stateCarrier', 'municipalityCarrier', 'wastes', 'folios'],
        methods: {
            print() {
                var newstr = document.getElementById('printMe').innerHTML
                document.body.innerHTML = newstr
                window.print()
                window.location.reload()
            }
        }
    }
</script>