<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    Bitácoras
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Salidas
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <selectize
                                    :options="allSubsidiaries"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una sede.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary"></selectize>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <selectize
                                    :options="typeWastes"
                                    :disabled="processing"
                                    :placeholder="'Seleccione un tipo de residuo.'"
                                    @input="typeWasteSelected"
                                    :value="setTypeWaste"></selectize>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <selectize
                                    :options="generators"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="generatorSelected"
                                    :busy="busyGenerators"
                                    :value="setGenerator"></selectize>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <selectize
                                    :options="carriers"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa transportista.'"
                                    @input="carrierSelected"
                                    :busy="busyCarriers"
                                    :value="setCarrier"></selectize>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <selectize
                                    :options="recipients"
                                    :disabled="processing"
                                    :placeholder="'Seleccione una empresa destinataria.'"
                                    @input="recipientSelected"
                                    :busy="busyRecipients"
                                    :value="setRecipient"></selectize>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="since"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha desde</label>

                                <input
                                    id="since"
                                    type="date"
                                    :disabled="processing"
                                    v-model="params.since"
                                    autocomplete="givensince"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="processing ? 'cursor-not-allowed' : 'cursor-default'" />
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="until"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha hasta</label>

                                <input
                                    id="until"
                                    type="date"
                                    :disabled="processing"
                                    v-model="params.until"
                                    autocomplete="givenuntil"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="processing ? 'cursor-not-allowed' : 'cursor-default'" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-span-1">
                <div class="flex flex-col xl:flex-row justify-between">

                    <div class="flex flex-row">
                        <div class="flex flex-row w-20">
                            <select
                                class="h-full w-max sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="params.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex xl:w-56 w-full">
                            <input
                                @paste="search"
                                @cut="search"
                                placeholder="Buscar"
                                class="appearance-none border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="params.search" @keyup="search" />
                        </div>
                    </div>

                    <div class="pt-4 xl:pt-0 grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <button
                            type="button"
                            @click="exported"
                            :class="processing || disabledExport ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="processing || disabledExport"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border sm:rounded-md bg-green-400 hover:bg-green-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Exportar
                        </button>
                        <button
                            type="button"
                            @click="filter"
                            :class="processing || disabledFilter ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="processing || disabledFilter"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border sm:rounded-md bg-blue-400 hover:bg-blue-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader></loader>
                                </td>
                            </tr>

                            <tr v-if="manifestWastes?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="manifestWastes.data?.length > 0 && !loading" v-for="(manifestWaste, index) in manifestWastes.data" :key="index">
                                <table-body-item>
                                    {{manifestWaste.manifest.folio}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.waste.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.manifest.client.data.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.manifest.carrier.data.full_name}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.manifest.recipient.data.full_name}}
                                </table-body-item>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="manifestWastes" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment               from 'moment'
    import Spin                 from '@/Components/Spin'
    import BtnSuccess           from '@/Components/Buttons/Success'
    import BtnPrimary           from '@/Components/Buttons/Primary'
    import Selectize            from '@/Components/Inputs/Selectize'
    import Pagination           from '@/Components/Pagination/Pagination'
    import Loader               from '@/Components/Loader'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'

    import { pickBy, throttle } from 'lodash';

    const heading = ['Folio', 'Residuo', 'Generadora', 'Transportista', 'Destinatario']

    export default {
        components: {
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            Spin,
            BtnSuccess,
            BtnPrimary,
            Selectize,
            Pagination,
            Loader,
            TableContainer,
            TableBodyItem,
            InertiaLink
        },
        props: ['subsidiaries', 'manifestWastes', 'filters'],
        data() {
            return {
                params: {
                    subsidiary:     this.filters.subsidiary,
                    generator:      this.filters.generator,
                    carrier:        this.filters.carrier,
                    recipient:      this.filters.recipient,
                    typeWaste:      this.filters.typeWaste,
                    since:          this.filters.since,
                    until:          this.filters.until,

                    search:         this.getTableVariables().search,
                    perPage:        this.getTableVariables().perPage,
                    pagination:     this.getTableVariables().pagination,
                },
                typeWastes:         [{id: -1, name: 'TODOS'}, {id: 1, name: 'PELIGROSOS'}, {id: 2, name: 'NO PELIGROSOS'}],
                generators:         [],
                carriers:           [],
                recipients:         [],
                processing:         false,
                loading:            false,
                csrf:               document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showFile:           false,
                showMessage:        false,
                busyGenerators:     false,
                busyCarriers:       false,
                busyRecipients:     false,
                heading,
                tableVariables:     null,
                setSubsidiary:      null,
                setTypeWaste:       null,
                setGenerator:       null,
                setCarrier:         null,
                setRecipient:       null,

                disabledFilter:     true,
                disabledExport:     true,
            }
        },
        methods: {
            filter: throttle(function () {
                this.processing = true
                this.$inertia.get(this.route('dashboard.binnacles.departures.index'), pickBy(this.params), {
                    replace: true,
                    preserveState: true,
                    preserveScroll: (page) => Object.keys(page.props.errors).length,
                    onSuccess: (page) => {
                        this.processing = false
                        this.setSelectizes(page)
                    }
                })
            }, 150),
            subsidiarySelected(value) {
                this.params.subsidiary  = value ? value.id : null
            },
            typeWasteSelected(value) {
                if (value) {
                    this.params.typeWaste   = value.id
                    this.params.generator   = null
                    this.setGenerator       = null
                    this.params.carrier     = null
                    this.setCarrier         = null
                    this.params.recipient   = null
                    this.setRecipient       = null
                    this.getGeneratorsByTypeWaste(value.id)
                    this.getCarriersByTypeWaste(value.id)
                    this.getRecipientsByTypeWaste(value.id)
                } else {
                    this.params.typeWaste   = null
                    this.params.generator   = null
                    this.setGenerator       = null
                    this.params.carrier     = null
                    this.setCarrier         = null
                    this.params.recipient   = null
                    this.setRecipient       = null
                }
            },
            async getGeneratorsByTypeWaste(typeWaste) {
                this.busyGenerators     = true
                this.generators         = []
                await axios.post(route('api.clients.typeWaste'), {typeWaste: typeWaste}).then(res => {
                    this.generators     = [{id: -1, name: 'TODAS'}, ...res.data]
                })
                this.busyGenerators     = false
            },
            async getCarriersByTypeWaste(typeWaste) {
                this.busyCarriers       = true
                this.carriers           = []
                await axios.post(route('api.carriers.typeWaste'), {typeWaste: typeWaste}).then(res => {
                    this.carriers       = [{id: -1, name: 'TODAS'}, ...res.data]
                })
                this.busyCarriers       = false
            },
            async getRecipientsByTypeWaste(typeWaste) {
                this.busyRecipients     = true
                this.recipients         = []
                await axios.post(route('api.recipients.typeWaste'), {typeWaste: typeWaste}).then(res => {
                    this.recipients     = [{id: -1, name: 'TODAS'}, ...res.data]
                })
                this.busyRecipients     = false
            },
            generatorSelected(value) {
                this.params.generator   = value ? value.id : null
            },
            carrierSelected(value) {
                this.params.carrier     = value ? value.id : null
            },
            recipientSelected(value) {
                this.params.recipient   = value ? value.id : null
            },
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(this.$page.url.substring(0,this.$page.url.indexOf('?'))))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.params.pagination          = 1
                }
                this.tableVariables.perPage         = this.params.perPage
                localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(this.tableVariables))
                this.filter()
            },
            search: throttle(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.params.pagination          = 1
                }
                this.tableVariables.search          = this.params.search
                localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(this.tableVariables))
                this.filter()
            }, 150),
            async currentPage(page) {
                this.tableVariables.pagination  = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(this.tableVariables))
                this.params.pagination          = page.substring(page.indexOf("=") + 1)
                this.filter()
            },
            exported() {
                window.open(route('dashboard.binnacles.departures.download', this.params), '_blank')
            },
            setSelectizes(page) {
                this.setSubsidiary = page.props.filters.subsidiary
                this.setTypeWaste  = page.props.filters.typeWaste
                this.setGenerator  = page.props.filters.generator
                this.setCarrier    = page.props.filters.carrier
                this.setRecipient  = page.props.filters.recipient
            }
        },
        computed: {
            allSubsidiaries() {
                return [
                    {id: -1, name: 'Todas'},
                    ...this.subsidiaries,
                ];
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url.substring(0,this.$page.url.indexOf('?'))}-table`)) {
                const table = {
                    perPage: 25,
                    pagination: 1,
                    search: ''
                }
                window.localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(this.$page.url.substring(0,this.$page.url.indexOf('?'))))

            if (this.tableVariables.pagination != this.filters.pagination) {
                console.log(this.filters)
                this.tableVariables.pagination  = this.filters.pagination
                this.params.pagination          = this.filters.pagination
                localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(this.tableVariables))
            }

            if (this.$page.props.filters.subsidiary) {
                this.setSubsidiary      = this.$page.props.filters.subsidiary
                this.params.subsidiary  = this.$page.props.filters.subsidiary
            }

            if (this.$page.props.filters.typeWaste) {
                this.setTypeWaste       = this.$page.props.filters.typeWaste
                this.params.typeWaste   = this.$page.props.filters.typeWaste
                this.getGeneratorsByTypeWaste(this.$page.props.filters.typeWaste)
                this.getCarriersByTypeWaste(this.$page.props.filters.typeWaste)
                this.getRecipientsByTypeWaste(this.$page.props.filters.typeWaste)
            }

            if (this.$page.props.filters.generator) {
                this.setGenerator      = this.$page.props.filters.generator
                this.params.generator  = this.$page.props.filters.generator
            }

            if (this.$page.props.filters.carrier) {
                this.setCarrier      = this.$page.props.filters.carrier
                this.params.carrier  = this.$page.props.filters.carrier
            }

            if (this.$page.props.filters.recipient) {
                this.setRecipient      = this.$page.props.filters.recipient
                this.params.recipient  = this.$page.props.filters.recipient
            }
        },
        updated() {
            if (this.tableVariables.pagination != this.filters.pagination) {
                this.tableVariables.pagination  = this.filters.pagination
                this.params.pagination          = this.filters.pagination
                localStorage.setItem(this.$page.url.substring(0,this.$page.url.indexOf('?')), JSON.stringify(this.tableVariables))
            }

            if (this.params.subsidiary != this.filters.subsidiary || this.params.generator  != this.filters.generator || this.params.carrier    != this.filters.carrier || this.params.recipient  != this.filters.recipient || this.params.typeWaste  != this.filters.typeWaste || this.params.since      != this.filters.since || this.params.until      != this.filters.until) {
                this.disabledExport = true
            } else {
                this.disabledExport = false
            }
        },
        watch: {
            params: {
                deep: true,
                handler(param) {
                    if (param.subsidiary == null && param.generator == null && param.carrier == null && param.recipient == null && param.until == null && param.since == null && param.typeWaste == null) {
                        this.disabledFilter = true
                        this.disabledExport = true
                    } else {
                        this.disabledFilter = false
                        this.disabledExport = false
                    }

                    if (param.subsidiary != this.filters.subsidiary || param.generator  != this.filters.generator || param.carrier    != this.filters.carrier || param.recipient  != this.filters.recipient || param.typeWaste  != this.filters.typeWaste || param.since      != this.filters.since || param.until      != this.filters.until) {
                        this.disabledExport = true
                    } else {
                        this.disabledExport = false
                    }
                }
            }
        }
    }
</script>