<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.consolidated.index')">
                        Manifiestos consolidados
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    {{manifest.folio}}
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del manifiesto consolidado
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Folio
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{manifest.folio}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Tipo de residuo
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                {{manifest.type_waste}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa generadora
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div v-if="manifest?.client">
                                    {{manifest.client.data.name}}
                                </div>
                                <div v-else-if="manifest?.generator">
                                    {{manifest.generator.data.name}}
                                </div>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa transportista
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{manifest.carrier.data.full_name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa destinataria
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{manifest.recipient.data.full_name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Fecha de ingreso
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest.admission_at">{{moment(manifest.admission_at)}}</span>
                                <span v-else>Sin fecha</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Manifiesto adjuntado
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <a target="_blank" v-if="manifest.file" :href="`${filesUrl}${manifest.file}`"  class="text-orange-500 hover:text-orange-400">
                                    {{manifest.file.substring(10)}}
                                </a>
                                <span v-else class="text-orange-500 hover:text-orange-400 uppercase">Sin manifiesto adjuntado</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Manifiesto valido
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span v-if="manifest.status_manifest">Si</span>
                                <span v-else>No</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div v-for="(manifest_waste, index) in manifest.manifest_wastes" :key="index" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Residuo #{{index + 1}}
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre del residuo
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest_waste.waste.status_rpbi || manifest_waste.waste.type_waste == 'No peligrosos'">{{manifest_waste.waste.name}}</span>
                                <span v-else>{{manifest_waste.waste.integrated_transport_name}}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Cantidad
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest_waste.quantity">{{manifest_waste.quantity}}</span>
                                <span v-else>Sin cantidad</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Contenedor
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest_waste.container">{{manifest_waste.container.name}}</span>
                                <span v-else>Sin contenedor</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Cantidad total
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest_waste.quantity">{{manifest_waste.total_quantity}}</span>
                                <span v-else>Sin cantidad total</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Medida
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="manifest_waste.measurement">{{manifest_waste.measurement.name}}</span>
                                <span v-else>Sin medida</span>
                            </dd>
                        </div>

                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Folio consolidado
                            </dt>
                            <dd v-if="manifest_waste.consolidated_manifests.length" class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-for="(consolidated_manifest, index) in manifest_waste.consolidated_manifests" :key="index">
                                    <a v-if="consolidated_manifest.consolidated.status == 'open'"           :href="route('dashboard.consolidated.open.show',  consolidated_manifest.consolidated.id)"       class="text-orange-500 hover:text-orange-400" target="_blank">{{consolidated_manifest.consolidated.folio}}</a>
                                    <a v-if="consolidated_manifest.consolidated.status == 'to-finalize'"    :href="route('dashboard.consolidated.finalize.show',  consolidated_manifest.consolidated.id)"   class="text-orange-500 hover:text-orange-400" target="_blank">{{consolidated_manifest.consolidated.folio}}</a>
                                    <a v-else-if="consolidated_manifest.consolidated.status == 'finish'"    :href="route('dashboard.consolidated.finish.show', consolidated_manifest.consolidated.id)"      class="text-orange-500 hover:text-orange-400" target="_blank">{{consolidated_manifest.consolidated.folio}}</a>
                                    <a v-else-if="consolidated_manifest.consolidated.status == 'cancelled'" :href="route('dashboard.consolidated.cancelled.show', consolidated_manifest.consolidated.id)"   class="text-orange-500 hover:text-orange-400" target="_blank">{{consolidated_manifest.consolidated.folio}}</a>

                                    <span v-if="index < (manifest_waste.consolidated_manifests.length-1)">, </span>
                                </span>
                            </dd>

                            <dd v-else class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span>Sin folio</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import BreadcumbNav from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment from 'moment';

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        },
        props: ['manifest'],
        data() {
            return {
                filesUrl: process.env.APP_URL + '/files/',
            }
        },
        methods: {
            moment(date) {
                return moment(date).format('DD/MM/YYYY')
            },
        }
    }
</script>