<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.authorizations.index')">
                        Autorizaciónes
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Crear
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">

                <div class="shadow rounded-md bg-white p-6">

                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 sm:col-span-9 lg:col-span-10">
                            <label
                                for="subsidiary"
                                class="mb-1 block text-sm font-semibold text-gray-700">Sucursal</label>
                            <v-select
                                :disabled="form.processing"
                                :selectOnTab="true"
                                :tabindex="1"
                                placeholder="Seleccionar una sucursal"
                                label="descriptive_name"
                                v-model="form.subsidiary"
                                :reduce="(option) => option.id"
                                :options="subsidiaries">
                                <template #no-options>
                                    No se encontraron resultados.
                                </template>
                            </v-select>
                            <div
                                v-if="form.errors.subsidiary"
                                class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                        </div>

                        <div class="col-span-12 sm:col-span-3 lg:col-span-2">
                            <label 
                                for="viewAll"
                                class="block text-sm font-semibold text-gray-700 mb-1">Compartir en todas</label>
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input 
                                        id="viewAll"
                                        type="checkbox"
                                        :value="form.viewAll"
                                        :disabled="form.processing"
                                        v-model="form.viewAll"
                                        @click="viewAllChange"
                                        class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                        :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="viewAll" class="font-medium text-gray-700" v-text="viewAllLabel"></label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-span-12">
                            <label 
                                for="name"
                                class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                            <input 
                                id="name"
                                type="text"
                                placeholder="Escribe el nombre para identificar la autorización"
                                :tabindex="2"
                                :disabled="form.processing"
                                v-model="form.name"
                                autocomplete="givenname"
                                class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-25 placeholder-gray-800"
                                :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                            <div
                                v-if="form.errors.name"
                                class="text-red-500 text-xs mt-1">{{ form.errors.name }}</div>
                        </div>
                        
                        <div class="col-span-12">
                            <label
                                for="file"
                                class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar autorización</label>
                                
                            <file :open="showFileModal" @click="openFileModal" @closeFileModal="closeFileModal" @returnFile="getFile"></file>

                            <div
                                v-if="form.errors.fileAuth" 
                                class="text-red-500 text-xs mt-1">{{ form.errors.fileAuth }}</div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.authorizations.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Agregar
                    </btn-success>
                </div>

            </form>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import Spin             from '@/Components/Spin'
    import vSelect          from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import File             from '@/Components/Inputs/FileUpload'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            Spin,
            vSelect,
            File
        },
        props: ['subsidiaries'],
        data() {
            return {
                form: this.$inertia.form({
                    name:       '',
                    fileAuth:   null,
                    subsidiary: null,
                    viewAll:    false
                }),
                viewAllLabel:   'No',
                showFileModal:  false
            }
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.authorizations.store'), {preserveScroll: true});
            },
            openFileModal() {
                this.showFileModal = true
            },
            closeFileModal(val) {
                this.showFileModal = false
            },
            getFile(file) {
                this.form.fileAuth = file
            },
            viewAllChange() {
                this.form.viewAll = !this.form.viewAll
                this.viewAllLabel = this.form.viewAll ? 'Si' : 'No'
            }
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    if (Object.entries(data.errors).length) {
                        if (data.subsidiary != null)    this.form.errors.subsidiary = ''
                        if (data.name != '')            this.form.errors.name       = ''
                        if (data.fileAuth != null)      this.form.errors.fileAuth   = ''
                    }
                }
            }
        }
    }
</script>