<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.representatives.index')">
                        Representantes ambientales
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.representatives.show', representative.id)">
                        {{representative.name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input 
                                    id="name"
                                    type="text"
                                    required
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.name }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                                <label 
                                    for="email"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Correo electrónico</label>
                                <input 
                                    id="email"
                                    type="email"
                                    :disabled="form.processing"
                                    v-model="form.email"
                                    autocomplete="givenemail"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.email"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.email }}</div>
                            </div>

                            <div class="col-span-12 lg:col-span-4">
                                <label 
                                    for="mobile"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Teléfono</label>
                                <input 
                                    id="mobile"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.mobile"
                                    autocomplete="givenmobile"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.mobile"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.mobile }}</div>
                            </div>
                        </div>
                    </div>

                     <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="password"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Contraseña</label>

                                <div class="relative">
                                    <input
                                    v-model="form.password"
                                    :disabled="form.processing"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'"
                                    autocomplete="current-password"
                                    :type="showPassword ? 'text' : 'password'"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25">
                                    
                                    <div @click="toggleShowPassword" class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                        <eye-icon v-if="showPassword" class="w-5 text-orange-500 hover:text-orange-500"></eye-icon>
                                        <eye-off-icon v-else class="w-5 text-gray-400"></eye-off-icon>
                                    </div>
                                </div>
                                
                                <div
                                    v-if="form.errors.password"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.password }}</div>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label 
                                    for="passwordConfirmation"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Confirmar contraseña</label>
                                <input
                                    v-model="form.passwordConfirmation"
                                    :disabled="form.processing"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'"
                                    autocomplete="confirm-password"
                                    :type="showPassword ? 'text' : 'password'"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25">
                                <div
                                    v-if="form.errors.passwordConfirmation"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.passwordConfirmation }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-5 text-right space-x-4">
                    <link-light :href="route('dashboard.representatives.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Actualizar
                    </btn-warning>
                </div>

            </form>

            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div class="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Empresas generadoras relacionadas
                        </h3>
                        <button type="button" class="text-green-500 hover:text-green-400" @click="openAddClientModal">Agregar empresa</button>
                    </div>
                    <div v-if="representative.clients.length" class="border-t border-gray-200">
                        <dl v-for="(client, index) in representative.clients" :key="index">
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div class="flex justify-between">
                                        <div>{{client.data.name}}</div>
                                        <div>
                                            <button type="button" class="text-red-500 hover:text-red-400" @click="openDeleteModal(client)">
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <div v-else class="border-t border-gray-200">
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Sin empresas relacionadas
                            </div>
                        </div>
                    </div>

                </div>
        </div>

        <dialog-modal :show="showDeleteModal">
            <template #content>
                <span>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">¿Estás seguro de que deseas eliminar "{{deleteParams.client.data.name}}"?</h3>
                    <p class="text-sm text-gray-500">Esta acción no se puede deshacer.</p>
                </span>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="closeDeleteModal" :disabled="deleteParams.processing">Cancelar</btn-light>
                    <btn-danger @click="destroy" :disabled="deleteParams.processing">Eliminar</btn-danger>
                </div>
            </template>
        </dialog-modal>

        <DialogModalWO :show="showAddClientModal">
            <template #content>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label 
                            for="client" 
                            class="block text-sm font-semibold text-gray-700 mb-1">Empresa generadora</label>

                        <selectize
                            :options="clients"
                            :disabled="addClientParams.processing"
                            :placeholder="'Seleccione una empresa generadora.'"
                            @input="clientSelected"></selectize>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="closeAddClientModal" :disabled="addClientParams.processing">Cancelar</btn-light>
                    <btn-success @click="addClient" :disabled="addClientParams.processing || !addClientParams.clientId">Agregar</btn-success>
                </div>
            </template>
        </DialogModalWO>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Spin             from '@/Components/Spin'
    import EyeIcon          from '@/Components/Icons/HeroIcons/outline/Eye'
    import EyeOffIcon       from '@/Components/Icons/HeroIcons/outline/EyeOff'
    import DialogModal      from '@/Components/DialogModal'
    import DialogModalWO    from '@/Components/DialogModalWithoutOverflow'
    import BtnLight         from '@/Components/Buttons/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import BtnDanger        from '@/Components/Buttons/Danger'
    import Selectize        from '@/Components/Inputs/Selectize'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            Spin,
            EyeIcon,
            EyeOffIcon,
            DialogModal,
            DialogModalWO,
            BtnLight,
            BtnSuccess,
            BtnDanger,
            Selectize
        },
        props: ['representative', 'clients'],
        data() {
            return {
                form: this.$inertia.form({
                    name:                   this.representative.name,
                    email:                  this.representative.email,
                    mobile:                 this.representative.mobile,
                    password:               '',
                    passwordConfirmation:   ''
                }),
                showPassword:               false,
                showDeleteModal:            false,
                showAddClientModal:         false,
                deleteParams: this.$inertia.form({
                    representativeId:       this.representative.id,
                    clientId:               null,
                    client:                 null
                }),
                addClientParams: this.$inertia.form({
                    representativeId:       this.representative.id,
                    clientId:               null
                })
            }
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.representatives.update', this.representative.id), {
                    preserveState: (page) => Object.keys(page.props.errors).length,
                    preserveScroll: (page) => Object.keys(page.props.errors).length,
                });
            },
            toggleShowPassword() {
                this.showPassword = !this.showPassword
            },
            openDeleteModal(client) {
                console.log(client)
                this.deleteParams.clientId          = client.id
                this.deleteParams.client            = client
                this.showDeleteModal                = true
            },
            closeDeleteModal() {
                this.deleteParams.clientId          = null
                this.deleteParams.client            = null
                this.showDeleteModal                = false
            },
            destroy() {
                this.deleteParams.post(route('dashboard.representatives.destroyClient'), this.deleteParams, {
                    preserveState: (page) => Object.keys(page.props.errors).length,
                    preserveScroll: (page) => Object.keys(page.props.errors).length,
                    onSuccess: page => {
                        console.log('a')
                        this.closeDeleteModal()
                    }
                })
            },
            openAddClientModal() {
                this.showAddClientModal = true
            },
            closeAddClientModal() {
                this.addClientParams.clientId = null
                this.showAddClientModal       = false
            },
            addClient() {
                this.addClientParams.post(route('dashboard.representatives.addClient', this.addClientParams), {
                    preserveState: (page)   => Object.keys(page.props.errors).length,
                    preserveScroll: (page)  => Object.keys(page.props.errors).length,
                    onSuccess: page => {
                        this.closeAddClientModal()
                    }
                })
            },
            clientSelected(value) {
                this.addClientParams.clientId = value ? value.id : null
            }
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    if (Object.entries(data.errors).length) {
                        if (data.name != '')    this.form.errors.name       = ''
                        if (data.email != '')   this.form.errors.email      = ''
                        if (data.mobile != '')  this.form.errors.mobile     = ''
                    }

                    if (data.password != data.passwordConfirmation) {
                        this.form.errors.password = 'Las contraseñas deben coincidir'
                    } else {
                        this.form.errors.password = ''
                    }
                }
            }
        }
    }
</script>