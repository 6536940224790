<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.consolidated.cancelled.index')">
                        Consolidados cancelados
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    {{consolidated.folio}}
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del consolidado
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Folio
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{consolidated.folio}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa generadora
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{consolidated?.subsidiary?.descriptive_name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa transportista
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{consolidated?.carrier?.data.full_name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Empresa destinataria
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{consolidated?.recipient?.data.full_name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Fecha de salida
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="consolidated.departure_at">{{moment(consolidated.departure_at)}}</span>
                                <span v-else>Sin fecha</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Manifiesto adjuntado
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <inertia-link v-if="consolidated.file" :href="`${filesUrl}${consolidated.file}`"  class="text-orange-500 hover:text-orange-400">
                                    {{consolidated.file.substring(13)}}
                                </inertia-link>
                                <span v-else class="text-orange-500 hover:text-orange-400 uppercase">Sin manifiesto adjuntado</span>
                            </dd>
                        </div>
                        <div v-if="consolidated.status == 'open' || consolidated.status == 'to-finalize'" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Manifiesto valido
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span v-if="consolidated.status_consolidated">Si</span>
                                <span v-else>No</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Orden de trabajo
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <a v-if="consolidated.order" :href="`${filesUrl}${consolidated.order}`" class="text-orange-500 hover:text-orange-400" target="_blank">
                                    {{consolidated.order}}
                                </a>
                                <span v-else class="text-orange-500 hover:text-orange-400 uppercase">Sin orden de trabajo</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Folios amparados
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Folios
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-for="(folio, index) in folios" :key="index">
                                    <a v-if="folio.status == 'to-consolidate'" :href="route('dashboard.manifests.consolidate.show', folio.id)" class="text-orange-500 hover:text-orange-400" target="_blank">{{folio.folio}}</a>
                                    <a v-else-if="folio.status == 'consolidated'" :href="route('dashboard.manifests.consolidated.show', folio.id)" class="text-orange-500 hover:text-orange-400" target="_blank">{{folio.folio}}</a>
                                    <a v-else-if="folio.status == 'cancelled'" :href="route('dashboard.manifests.cancelled.show', folio.id)" class="text-orange-500 hover:text-orange-400" target="_blank">{{folio.folio}}</a>
                                    <span v-if="index < (folios.length-1)">, </span>
                                </span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div v-for="(waste, index) in wastes" :key="index" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Residuo #{{index+1}}
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre del residuo
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span>{{waste.integrated_transport_name}}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Contenedor
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                <span v-if="waste.container">{{waste.container}}</span>
                                <span v-else>Sin contenedor</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment           from 'moment';

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        },
        props: ['consolidated', 'wastes', 'folios'],
        data() {
            return {
                filesUrl: process.env.APP_URL + '/files/orders/',
            }
        },
        methods: {
            moment(date) {
                return moment(date).format('DD/MM/YYYY')
            },
        }
    }
</script>