<template>
    <img :src="isotipo" :width="width" :height="height" class="animate-pulse mx-auto my-auto">
</template>

<script>
    export default {
        props: {
            width:  {type: String, default: '100px'},
            height: {type: String, default: '100px'}
        },
        data() {
            return {
                isotipo: process.env.APP_URL + '/storage/brand/new_isotipo.svg',
            }
        }
    }
</script>