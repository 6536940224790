<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.invoices.prefecture.index')">
                        Facturas prefacturadas
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.invoices.prefecture.show', invoice.id)">
                        {{invoice.uuid}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <input 
                                    id="subsidiary"
                                    type="text"
                                    disabled
                                    v-model="form.subsidiaryName"
                                    autocomplete="givensubsidiary"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div 
                                    v-if="form.errors.subsidiary" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="typeAgreement"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de contrato</label>
                                <input
                                    id="typeAgreement"
                                    type="text"
                                    disabled
                                    v-model="form.typeAgreement"
                                    autocomplete="giventypeAgreement"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div
                                    v-if="form.errors.typeAgreement" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeAgreement }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="manifest"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input 
                                    id="manifest"
                                    type="text"
                                    :disabled="true"
                                    v-model="form.manifest"
                                    autocomplete="givenmanifest"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div 
                                    v-if="form.errors.manifest" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.manifest }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generatorName"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <input 
                                    id="generatorName"
                                    type="text"
                                    disabled
                                    v-model="form.generatorName"
                                    autocomplete="givengeneratorName"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div 
                                    v-if="form.errors.generatorName" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.generatorName }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="additionalQuantity"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Kilos extra</label>
                                <input
                                    id="additionalQuantity"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.additionalQuantity"
                                    autocomplete="givenadditionalQuantity"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.additionalQuantity"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.additionalQuantity }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <div v-if="invoice.file" class="mb-2">
                                    <div class="mb-1 block text-sm font-semibold text-gray-700">Factura adjuntada</div>
                                    
                                    <inertia-link :href="`${filesUrl}${invoice.file}`"  class="flex items-center px-3 p-1.5 shadow-sm sm:rounded-md bg-white border border-gray-300 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                                        {{currentFileName}}
                                    </inertia-link>
                                </div>

                                <label
                                    for="fileInvoice"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar factura</label>
                                    
                                <date :open="showFileModal" @click="openFileModal" @closeFileModal="closeFileModal" @returnFile="getFile"></date>

                                <div
                                    v-if="form.errors.fileInvoice" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.fileInvoice }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="invoicedAt"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Fecha de factura</label>
                                <input 
                                    id="invoicedAt"
                                    type="date"
                                    :disabled="form.processing"
                                    v-model="form.invoicedAt"
                                    autocomplete="giveninvoicedAt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.invoicedAt" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.invoicedAt }}</div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.invoices.prefecture.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning @click="form.onlyEdit = false" type="submit" :disabled="form.processing">
                        <spin v-show="form.processing && form.onlyEdit == false"></spin>
                        Actualizar
                    </btn-warning>
                    <btn-primary @click="form.onlyEdit = true" type="submit" :disabled="form.processing">
                        <spin v-show="form.processing && form.onlyEdit == true"></spin>
                        Solo editar
                    </btn-primary>
                </div>
            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import BtnPrimary       from '@/Components/Buttons/Primary'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Date             from '@/Components/Inputs/Date'
    import moment           from 'moment';
    import Spin             from '@/Components/Spin'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            BtnPrimary,
            Selectize,
            Date,
            Spin
        },
        props: ['invoice', 'subsidiaries'],
        data() {
            return {
                form: this.$inertia.form({
                    _method:                    'PUT',
                    onlyEdit:                   false,
                    subsidiary:                 null,
                    manifest:                   null,
                    uuid:                       null,
                    typeAgreement:              null,
                    subsidiaryName:             null,
                    generatorName:              null,
                    additionalQuantity:         '',
                    basePrice:                  null,
                    additionalPrice:            null,
                    vat8:                       null,
                    vat16:                      null,
                    email:                      null,
                    invoicedAt:                 null,
                    fileInvoice:                null,
                    hasFile:                    false,
                }),
                typeAgreements:                 [{id: 1, name: 'RP'}, {id: 2, name: 'NRP'}, {id: 3, name: 'RPBI'}],
                setTypeAgreement:               null,
                setSubsidiary:                  null,
                setManifest:                    null,
                filesUrl:                       process.env.APP_URL + '/files/',
                currentFileName:                null,
                showFileModal:                  false,
            } 
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.invoices.prefecture.update', this.invoice.id), this.form, {preserveScroll: true, forceFormData: true})
            },
            init() {
                this.form.subsidiary             = this.invoice.subsidiary
                this.form.manifest               = this.invoice.manifest.folio
                this.form.typeAgreement          = this.invoice.TypeAgreementLabel
                this.form.subsidiaryName         = this.invoice.subsidiary.descriptive_name
                this.form.generatorName          = this.invoice.manifest.client.data.name
                this.form.additionalQuantity     = this.invoice.additional_quantity
                this.form.invoicedAt             = this.invoice.invoiced_at      ? moment(this.invoice?.invoiced_at.substring(0, 10)).format('YYYY-MM-DD') : null
                this.currentFileName             = this.invoice.file != null    ? this.invoice.file.substring(9) : null
                this.form.hasFile                = this.invoice.file != null    ? true : false
            },
            openFileModal() {
                this.showFileModal = true
            },
            closeFileModal() {
                this.showFileModal = false
            },
            getFile(file) {
                this.form.fileInvoice = file
            },
        },
        mounted() {
            this.init()
        }
    }
</script>