<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.invoices.prefecture.index')">
                        Facturas prefacturadas
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Crear
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <selectize
                                    :options="subsidiaries"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una sucursal.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary"
                                    :label="'descriptive_name'"
                                    :keys="['id', 'descriptive_name']"></selectize>
                                <div 
                                    v-if="form.errors.subsidiary" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de contrato</label>
                                <selectize
                                    :options="typeAgreements"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de contrato.'"
                                    @input="typeAgreementSelected"
                                    :value="setTypeAgreement"></selectize>
                                <div 
                                    v-if="form.errors.typeAgreement" 
                                    class="text-red-500 text-xs mt-2">{{ form.errors.typeAgreement }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="manifests"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <selectize
                                    :options="manifests"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un folio.'"
                                    @input="manifestSelected"
                                    :value="setManifest"
                                    :label="'folio'"
                                    :keys="['id', 'folio']"></selectize>
                                <div 
                                    v-if="form.errors.manifest" 
                                    class="text-red-500 text-xs mt-2">{{ form.errors.manifest }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="generatorName"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <input
                                    id="generatorName"
                                    type="text"
                                    disabled="true"
                                    v-model="form.generatorName"
                                    autocomplete="givengeneratorName"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div
                                    v-if="form.errors.generatorName"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.generatorName }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="additionalQuantity"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Kilos extra</label>
                                <input
                                    id="additionalQuantity"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.additionalQuantity"
                                    autocomplete="givenadditionalQuantity"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.additionalQuantity"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.additionalQuantity }}</div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.invoices.prefecture.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success @click="form.keepAdding=false" type="submit" :disabled="form.processing">
                        <spin v-show="form.processing && form.keepAdding == false"></spin>
                        Agregar
                    </btn-success>
                    <btn-success @click="form.keepAdding=true" type="submit" :disabled="form.processing">
                        <spin v-show="form.processing && form.keepAdding == true"></spin>
                        Seguir agregando
                    </btn-success>
                </div>
            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import BtnPrimary       from '@/Components/Buttons/Primary'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            BtnPrimary,
            Selectize,
            Date,
            Spin
        },
        props: ['subsidiaries', 'manifests'],
        data() {
            return {
                form: this.$inertia.form({
                    subsidiary:                 null,
                    manifest:                   null,
                    uuid:                       null,
                    typeAgreement:              null,
                    generatorName:              '',
                    additionalQuantity:         '',
                    basePrice:                  null,
                    additionalPrice:            null,
                    vat8:                       null,
                    vat16:                      null,
                    email:                      null,
                    keepAdding:                 false
                }),
                typeAgreements:                 [{id: 1, name: 'RP'}, {id: 2, name: 'NRP'}, {id: 3, name: 'RPBI'}],
                setTypeAgreement:               null,
                setSubsidiary:                  null,
                setManifest:                    null,
            } 
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.invoices.prefecture.store'), this.form, {forceFormData: true})
            },
            subsidiarySelected(value) {
                this.form.subsidiary = value ? value.id : null
            },
            manifestSelected(value) {
                this.form.errors.generatorName   = ''
                this.form.manifest               = value ? value.id : null
                if (value?.client?.agreement) {
                    this.form.generatorName     = value.client.agreement.business_name
                    this.form.basePrice         = value.client.agreement.base_price
                    this.form.additionalPrice   = value.client.agreement.additional_price
                    this.form.vat8              = value.client.agreement.vat_8
                    this.form.vat16             = value.client.agreement.vat_16
                    this.form.email             = value.client.agreement.email
                    this.form.uuid              = value.client.agreement.uuid
                } else {
                    this.form.errors.generatorName = 'La empresa generadora seleccionada no tiene un contrato.'
                }
            },
            typeAgreementSelected(value) {
                this.form.typeAgreement = value ? value.id : null
            }
        },
        updated() {
            if (this.form.keepAdding) {
                this.form.subsidiary             =   null
                this.form.manifest               =   null
                this.form.uuid                   =   null
                this.form.typeAgreement          =   null
                this.form.generatorName          =   ''
                this.form.additionalQuantity     =   ''
                this.form.basePrice              =   null
                this.form.additionalPrice        =   null
                this.form.vat8                   =   null
                this.form.vat16                  =   null
                this.form.email                  =   null
                this.form.keepAdding             =   false
                this.typeAgreements              =   [{id: 1, name: 'RP'}, {id: 2, name: 'NRP'}, {id: 3, name: 'RPBI'}]
                this.setSubsidiary               =   0
                this.setManifest                 =   0
                this.setTypeAgreement            =   0
                this.form.errors.generatorName   =   ''
            } else {
                this.setSubsidiary      = this.form.subsidiary
                this.setTypeAgreement   = this.form.typeAgreement
                this.setManifest        = this.form.manifest
            }
        },
        mounted() {
            this.form.errors.generatorName = ''
        }
    }
</script>