<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.agreements.index')">
                        Contratos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.agreements.show', agreement.id)">
                        {{agreement.uuid}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
                <form @submit.prevent="submit">
                    <div class="shadow sm:rounded-md bg-white">

                        <div class="px-6 pt-6 pb-3">
                            <div class="grid grid-cols-1 sm:grid-cols-12 gap-4">
                                <div class="col-span-1 sm:col-span-8">
                                    <label
                                        for="generator"
                                        class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                    <input 
                                        id="generator"
                                        type="text"
                                        :disabled="true"
                                        v-model="form.generatorName"
                                        autocomplete="givengenerator"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                    <div 
                                        v-if="form.errors.generator" 
                                        class="text-red-500 text-xs">{{ form.errors.generator }}</div>
                                </div>

                                <div class="col-span-1 sm:col-span-4">
                                    <label
                                        for="generator"
                                        class="mb-1 block text-sm font-semibold text-gray-700">Tipo de contrato</label>
                                    <selectize
                                        :options="typeAgreements"
                                        :disabled="form.processing"
                                        :placeholder="'Seleccione un tipo de contrato.'"
                                        @input="typeAgreementSelected"
                                        :value="setTypeAgreement"></selectize>
                                    <div 
                                        v-if="form.errors.typeAgreement" 
                                        class="text-red-500 text-xs mt-2">{{ form.errors.typeAgreement }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="px-6 py-3">
                            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                                <div class="col-span-1 sm:col-span-2 md:col-span-1">
                                    <label 
                                        for="businessName"
                                        class="block text-sm font-semibold text-gray-700 mb-1">Razón social</label>
                                    <input 
                                        id="businessName"
                                        type="text"
                                        :disabled="form.processing"
                                        v-model="form.businessName"
                                        autocomplete="givenbusinessName"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                        :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    <div
                                        v-if="form.errors.businessName"
                                        class="text-red-500 text-xs mt-2">{{ form.errors.businessName }}</div>
                                </div>

                                <div class="col-span-1 sm:col-span-2 md:col-span-1">
                                    <label 
                                        for="email" 
                                        class="block text-sm font-semibold text-gray-700 mb-1">Correo electrónico</label>
                                    <input 
                                        id="email"
                                        type="email"
                                        :disabled="form.processing"
                                        v-model="form.email"
                                        autocomplete="givenemail"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                        :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    <div
                                        v-if="form.errors.email" 
                                        class="text-red-500 text-xs mt-2">{{ form.errors.email }}</div>
                                </div>

                            </div>
                        </div>

                        <div class="px-6 pt-3 pb-6">
                            <div class="grid grid-cols-12 gap-4">

                                <div class="col-span-5">
                                    <label 
                                        for="basePrice" 
                                        class="block text-sm font-semibold text-gray-700 mb-1">Costo básico</label>
                                    <input 
                                        id="basePrice"
                                        type="number"
                                        :disabled="form.processing"
                                        v-model="form.basePrice"
                                        autocomplete="givenbasePrice"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                        :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    <div
                                        v-if="form.errors.basePrice"
                                        class="text-red-500 text-xs mt-2">{{ form.errors.basePrice }}</div>
                                </div>

                                <div class="col-span-5">
                                    <label 
                                        for="additionalPrice" 
                                        class="block text-sm font-semibold text-gray-700 mb-1">Costo por kilo extra</label>
                                    <input 
                                        id="additionalPrice"
                                        type="number"
                                        :disabled="form.processing"
                                        v-model="form.additionalPrice"
                                        autocomplete="givenadditionalPrice"
                                        class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                        :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    <div
                                        v-if="form.errors.additionalPrice" 
                                        class="text-red-500 text-xs mt-2">{{ form.errors.additionalPrice }}</div>
                                </div>

                                <div class="col-span-2">
                                    <label 
                                        for="vat"
                                        class="block text-sm font-semibold text-gray-700 mb-1">I.V.A.</label>
                                    
                                    <div class="inline-flex space-x-4">
                                        <div class="flex items-start">
                                            <div class="flex items-center h-5">
                                                <input
                                                    id="vat8"
                                                    type="checkbox"
                                                    :value="form.vat8"
                                                    :disabled="form.processing"
                                                    checked
                                                    v-model="form.vat8"
                                                    class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                            </div>
                                            <div class="ml-2 text-sm">
                                                <label for="vat8" class="font-medium text-gray-700">8 %</label>
                                            </div>
                                        </div>

                                        <div class="flex items-start">
                                            <div class="flex items-center h-5">
                                                <input
                                                    id="vat16"
                                                    type="checkbox"
                                                    :value="form.vat16"
                                                    :disabled="form.processing"
                                                    v-model="form.vat16"
                                                    class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                            </div>
                                            <div class="ml-2 text-sm">
                                                <label for="vat16" class="font-medium text-gray-700">16 %</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="pt-6 text-right space-x-4">
                        <link-light :href="route('dashboard.agreements.index')" :disabled="form.processing">Cancelar</link-light>
                        <btn-warning type="submit" :disabled="form.processing">
                            <spin v-show="form.processing"></spin>
                            Actualizar
                        </btn-warning>
                    </div>
                </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            Selectize,
            Spin
        },
        props: ['agreement'],
        data() {
            return {
                form: this.$inertia.form({
                    generator:                  null,
                    generatorName:              '',
                    uuid:                       '',
                    businessName:               '',
                    email:                      '',
                    basePrice:                  '0',
                    additionalPrice:            '0',
                    vat16:                      false,
                    vat8:                       false,
                    typeAgreement:              null
                }),
                typeAgreements:                 [{id: 1, name: 'RP'}, {id: 2, name: 'NRP'}, {id: 3, name: 'RPBI'}],
                setGenerator:                   null,
                setTypeAgreement:               null
            } 
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.agreements.update', this.agreement.id), this.form, {preserveScroll: true})
            },
            init() {
                this.form.generator         = this.agreement?.client ? this.agreement.client : null
                this.form.generatorName     = this.agreement?.client ? this.agreement.client.data.name : ''
                this.form.businessName      = this.agreement.business_name
                this.form.email             = this.agreement.email
                this.form.basePrice         = this.agreement.base_price
                this.form.additionalPrice   = this.agreement.additional_price
                this.form.vat16             = this.agreement.vat_16 == '0' ? false : true
                this.form.vat8              = this.agreement.vat_8  == '0' ? false : true
                this.form.typeAgreement     = this.agreement.type_agreement
                this.setTypeAgreement       = this.agreement.type_agreement
            },
            typeAgreementSelected(value) {
                this.form.typeAgreement = value ? value.id : null
            }
        },
        mounted() {
            this.init()
        },
        updated() {
            this.setGenerator       = this.form.generator      ? this.form.generator     : -1
            this.setTypeAgreement   = this.form.typeAgreement  ? this.form.typeAgreement : -1
        }
    }
</script>